import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, Text } from '@chakra-ui/react';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';

export const BodyBreadcrumb: React.FC = () => {
  const location = useLocation();

  return (
    <Breadcrumb separator='>'>
      {location.pathname
        .slice(1)
        .split('/')
        .map((name, idx, names) => [name, '/' + names.slice(0, idx + 1).join('/')])
        .map(([name, path]) => (
          <BreadcrumbItem key={path}>
            <BreadcrumbLink as={RouterLink} to={path}>
              <Text color='cyan.500'>{name}</Text>
            </BreadcrumbLink>
          </BreadcrumbItem>
        ))}
    </Breadcrumb>
  );
};
