export enum LowLevelDataType {
  Int8Be,
  Int8Le,
  Int16Be,
  Int16Le,
  Int24Be,
  Int24Le,
  Int32Be,
  Int32Le,
  Uint8Be,
  Uint8Le,
  Uint16Be,
  Uint16Le,
  Uint24Be,
  Uint24Le,
  Uint32Be,
  Uint32Le
}

export type LowLevelDataSizeType = {
  [key in LowLevelDataType]: number;
};

export type LowLevelDataConvertFunctionType = (buf: Uint8Array) => number;

export type LowLevelDataConvertType = {
  [key in LowLevelDataType]: LowLevelDataConvertFunctionType;
};

export const uint8arrayToInt8Be: LowLevelDataConvertFunctionType = (buf: Uint8Array) => (buf[0] << 24) / 16777216;
export const uint8arrayToInt8Le: LowLevelDataConvertFunctionType = (buf: Uint8Array) => (buf[0] << 24) / 16777216;
export const uint8arrayToInt16Be: LowLevelDataConvertFunctionType = (buf: Uint8Array) => ((buf[1] << 24) | (buf[0] << 16)) / 65536;
export const uint8arrayToInt16Le: LowLevelDataConvertFunctionType = (buf: Uint8Array) => ((buf[0] << 24) | (buf[1] << 16)) / 65536;
export const uint8arrayToInt24Be: LowLevelDataConvertFunctionType = (buf: Uint8Array) =>
  ((buf[2] << 24) | (buf[1] << 16) | (buf[0] << 8)) / 256;
export const uint8arrayToInt24Le: LowLevelDataConvertFunctionType = (buf: Uint8Array) =>
  ((buf[0] << 24) | (buf[1] << 16) | (buf[2] << 8)) / 256;
export const uint8arrayToInt32Be: LowLevelDataConvertFunctionType = (buf: Uint8Array) =>
  (buf[3] << 24) | (buf[2] << 16) | (buf[1] << 8) | buf[0];
export const uint8arrayToInt32Le: LowLevelDataConvertFunctionType = (buf: Uint8Array) =>
  (buf[0] << 24) | (buf[1] << 16) | (buf[2] << 8) | buf[3];
export const uint8arrayToUInt8Be: LowLevelDataConvertFunctionType = (buf: Uint8Array) => buf[0];
export const uint8arrayToUInt8Le: LowLevelDataConvertFunctionType = (buf: Uint8Array) => buf[0];
export const uint8arrayToUInt16Be: LowLevelDataConvertFunctionType = (buf: Uint8Array) => (buf[1] << 8) | buf[0];
export const uint8arrayToUInt16Le: LowLevelDataConvertFunctionType = (buf: Uint8Array) => (buf[0] << 8) | buf[1];
export const uint8arrayToUInt24Be: LowLevelDataConvertFunctionType = (buf: Uint8Array) => (buf[2] << 16) | (buf[1] << 8) | buf[0];
export const uint8arrayToUInt24Le: LowLevelDataConvertFunctionType = (buf: Uint8Array) => (buf[0] << 16) | (buf[1] << 8) | buf[2];
export const notSupportFunction: LowLevelDataConvertFunctionType = () => {
  throw new Error('not support function');
};

export const LowLevelDataSize: LowLevelDataSizeType = {
  [LowLevelDataType.Int8Be]: 1,
  [LowLevelDataType.Int8Le]: 1,
  [LowLevelDataType.Int16Be]: 2,
  [LowLevelDataType.Int16Le]: 2,
  [LowLevelDataType.Int24Be]: 3,
  [LowLevelDataType.Int24Le]: 3,
  [LowLevelDataType.Int32Be]: 4,
  [LowLevelDataType.Int32Le]: 4,
  [LowLevelDataType.Uint8Be]: 1,
  [LowLevelDataType.Uint8Le]: 1,
  [LowLevelDataType.Uint16Be]: 2,
  [LowLevelDataType.Uint16Le]: 2,
  [LowLevelDataType.Uint24Be]: 3,
  [LowLevelDataType.Uint24Le]: 3,
  [LowLevelDataType.Uint32Be]: 4,
  [LowLevelDataType.Uint32Le]: 4
};

export const LowLevelDataConvert: LowLevelDataConvertType = {
  [LowLevelDataType.Int8Be]: uint8arrayToInt8Be,
  [LowLevelDataType.Int8Le]: uint8arrayToInt8Le,
  [LowLevelDataType.Int16Be]: uint8arrayToInt16Be,
  [LowLevelDataType.Int16Le]: uint8arrayToInt16Le,
  [LowLevelDataType.Int24Be]: uint8arrayToInt24Be,
  [LowLevelDataType.Int24Le]: uint8arrayToInt24Le,
  [LowLevelDataType.Int32Be]: uint8arrayToInt32Be,
  [LowLevelDataType.Int32Le]: uint8arrayToInt32Le,
  [LowLevelDataType.Uint8Be]: uint8arrayToUInt8Be,
  [LowLevelDataType.Uint8Le]: uint8arrayToUInt8Le,
  [LowLevelDataType.Uint16Be]: uint8arrayToUInt16Be,
  [LowLevelDataType.Uint16Le]: uint8arrayToUInt16Le,
  [LowLevelDataType.Uint24Be]: uint8arrayToUInt24Be,
  [LowLevelDataType.Uint24Le]: uint8arrayToUInt24Le,
  [LowLevelDataType.Uint32Be]: notSupportFunction,
  [LowLevelDataType.Uint32Le]: notSupportFunction
};
