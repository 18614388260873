import { useAuthenticator } from '@aws-amplify/ui-react';
import { Alert, AlertIcon, Button, useDisclosure, VStack } from '@chakra-ui/react';
import { API } from 'aws-amplify';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchProjectBundle, fetchProjects } from '../api/api';
import { BodyLayout } from '../components/BodyLayout';
import ConfigDetailsModal from '../components/device/ConfigDetailsModal';
import { ConfigTable } from '../components/device/ConfigTable';
import { CreateConfigForm, CreateConfigFormType } from '../components/device/CreateConfigForm';
import LoadingBox from '../components/LoadingBox';
import { Navbar } from '../components/Navbar';
import { ProjectSidebar } from '../components/project/ProjectSidebar';
import { ProjectContext, ProjectContextType } from '../stores/ProjectContext';
import { ConfigType } from '../types/types';
import { templateConfigs, templateDevices } from '../utils/mock';

const DeviceConfig: React.FC = () => {
  const { projectRefId } = useParams();
  const { user } = useAuthenticator();

  const {
    projects,
    setProjects,
    devices,
    setDevices,
    configs,
    setConfigs,
    currentProject,
    setCurrentProject,
    subjects,
    setSubjects,
    sessions,
    setSessions,
    annotations,
    setAnnotations,
    files,
    setFiles
  } = useContext<ProjectContextType>(ProjectContext);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isDetailsModalOpen, onClose: onDetailsModalClose, onOpen: onDetailsModalOpen } = useDisclosure();
  const [currentConfig, setCurrentConfig] = useState<ConfigType | null>(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [createdConfig, setCreatedConfig] = useState<ConfigType | null>(null);

  useEffect(() => {
    if (!projects) {
      /* load projects */
      setIsLoading(true);
      fetchProjects()
        .then(res => {
          const { projects, devices } = res;
          /* clear current project and bundle */
          setSubjects(null);
          setSessions(null);
          setConfigs(null);
          setAnnotations(null);
          setCurrentProject(null);
          setFiles(null);
          /* set projects */
          setProjects(projects);
          setDevices(devices);
        })
        .catch(err => {
          setError(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [projects]);

  useEffect(() => {
    if (projects) {
      const project = projects.find(project => project.projectRefId === projectRefId);
      if (!project) {
        console.error(`invalid project ${projectRefId}`);
        return;
      }
      /* check if project ref id is not changed */
      if (currentProject?.projectRefId === project.projectRefId) {
        return;
      }

      setIsLoading(true);
      fetchProjectBundle(project.projectId)
        .then(res => {
          const { subjects, sessions, annotations, configs } = res;
          setSubjects(subjects);
          setSessions(sessions);
          setConfigs(configs);
          setAnnotations(annotations);
          setFiles(null);
        })
        .catch(err => {
          setError(err);
        })
        .finally(() => {
          setIsLoading(false);
        });

      /* set project and reload bundle */
      setCurrentProject(project);
    }
  }, [projects]);

  const createConfig = (config: CreateConfigFormType) => {
    if (!currentProject?.projectId) {
      console.error('invalid project');
      return;
    }
    API.post('labcloud-api', `projects/${currentProject.projectId}/configs`, {
      body: {
        deviceBrand: config.deviceBrand,
        deviceModel: config.deviceModel,
        note: config.note,
        configValues: config.configValues
      }
    }).then(res => {
      const config = res['config'] as ConfigType;
      if (configs) {
        setConfigs([...configs, config]);
        setCreatedConfig(config);
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 5000); // Hide the alert after 5 seconds
      }
    });
  };

  const openDetailsModal = (config: ConfigType | null) => {
    if (!config) return;

    setCurrentConfig(config);
    onDetailsModalOpen();
  };

  return (
    <Navbar userName={user.username ?? ''} organizationName='BCI Lab'>
      <ProjectSidebar projectName={currentProject?.name ?? ''} projectRefId={currentProject?.projectRefId ?? ''}>
        <BodyLayout>
          <VStack align='flex-end' mb={5}>
            <Button colorScheme='teal' onClick={onOpen}>
              + Config {isDetailsModalOpen}
            </Button>
          </VStack>
          {isLoading && <LoadingBox></LoadingBox>}
          {showAlert && createdConfig && (
            <Alert status='success' mb={5}>
              <AlertIcon />
              (ID: {createdConfig.configId}) {createdConfig.note} is created.
            </Alert>
          )}
          {!isLoading && configs && (
            <>
              <CreateConfigForm
                templateDevices={templateDevices}
                templateConfigs={templateConfigs}
                onSubmit={createConfig}
                onClose={onClose}
                isOpen={isOpen}
              ></CreateConfigForm>
              {currentConfig && (
                <ConfigDetailsModal config={currentConfig} isOpen={isDetailsModalOpen} onClose={onDetailsModalClose}></ConfigDetailsModal>
              )}
              <ConfigTable configs={configs} onRowClick={openDetailsModal}></ConfigTable>
            </>
          )}
        </BodyLayout>
      </ProjectSidebar>
    </Navbar>
  );
};

export default DeviceConfig;
