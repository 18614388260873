import { Box } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { BodyBreadcrumb } from './Breadcrumb';

type BodyLayoutProps = {
  children: ReactNode;
};

export const BodyLayout: React.FC<BodyLayoutProps> = ({ children }) => {
  return (
    <Box flex='1' minH='100vh' bg='gray.100'>
      <Box p={5}>
        <BodyBreadcrumb></BodyBreadcrumb>
        {children}
      </Box>
    </Box>
  );
};
