import { Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue } from '@chakra-ui/react';
import { AnnotationType } from '../../types/types';

type AnnotationTableProps = {
  annotations: AnnotationType[];
  onRowClick?: (project: AnnotationType | null) => void;
};

export const AnnotationTable: React.FC<AnnotationTableProps> = ({ annotations, onRowClick }) => {
  const handleRowClick = (e: React.MouseEvent<HTMLTableRowElement>) => {
    e.stopPropagation();
    if (onRowClick) {
      const annotationId = e.currentTarget.id;
      onRowClick(annotations.find(annotation => annotation.annotationId == annotationId) ?? null);
    }
  };

  return (
    <TableContainer>
      <Table variant='simple'>
        <Thead>
          <Tr bg={useColorModeValue('gray.300', 'gray.900')}>
            <Th>
              <Text align='center'>ID</Text>
            </Th>
            <Th>
              <Text align='center'>Title</Text>
            </Th>
            <Th>
              <Text align='center'>Message</Text>
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {annotations.map(annotation => (
            <Tr
              key={annotation.annotationId}
              cursor='pointer'
              borderRadius='3xl'
              _hover={{ bg: 'cyan.300', color: 'white', borderRadius: '3xl' }}
            >
              <Td>
                <Text align='center'>{annotation.annotationRefId} </Text>
              </Td>
              <Td>
                <Text align='center'>{annotation.title}</Text>
              </Td>
              <Td>
                <Text align='center'>{annotation.message}</Text>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};
