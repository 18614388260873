import { useAuthenticator } from '@aws-amplify/ui-react';
import { Button, useDisclosure, VStack } from '@chakra-ui/react';
import { API } from 'aws-amplify';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchFiles, fetchProjectBundle, fetchProjects } from '../api/api';
import { BodyLayout } from '../components/BodyLayout';
import LoadingBox from '../components/LoadingBox';
import { Navbar } from '../components/Navbar';
import { ProjectSidebar } from '../components/project/ProjectSidebar';
import { CreateSessionForm, CreateSessionFormType } from '../components/session/CreateSessionForm';
import SessionDetailsModal from '../components/session/SessionDetailsModal';
import { SessionTable } from '../components/session/SessionTable';
import { ProjectContext, ProjectContextType } from '../stores/ProjectContext';
import { SessionType } from '../types/types';

const Session: React.FC = () => {
  const navigate = useNavigate();
  const { projectRefId } = useParams();
  const { user } = useAuthenticator();

  const {
    projects,
    setProjects,
    devices,
    setDevices,
    configs,
    setConfigs,
    currentProject,
    setCurrentProject,
    subjects,
    setSubjects,
    sessions,
    setSessions,
    annotations,
    setAnnotations,
    files,
    setFiles
  } = useContext<ProjectContextType>(ProjectContext);

  const { isOpen, onClose, onOpen } = useDisclosure();
  const { isOpen: isDetailsModalOpen, onClose: onDetailsModalClose, onOpen: onDetailsModalOpen } = useDisclosure();
  const [currentSession, setCurrentSession] = useState<SessionType | null>(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const fetchData = () => {
    if (currentProject) {
      setIsLoading(true);
      fetchProjectBundle(currentProject.projectId)
        .then(res => {
          const { subjects, sessions, annotations, configs } = res;
          setSubjects(subjects);
          setSessions(sessions);
          setConfigs(configs);
          setAnnotations(annotations);
          setFiles(null);
        })
        .catch(err => {
          setError(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    if (!projects) {
      /* load projects */
      setIsLoading(true);
      fetchProjects()
        .then(res => {
          const { projects, devices } = res;
          /* clear current project and bundle */
          setSubjects(null);
          setSessions(null);
          setConfigs(null);
          setAnnotations(null);
          setCurrentProject(null);
          setFiles(null);
          /* set projects */
          setProjects(projects);
          setDevices(devices);
        })
        .catch(err => {
          setError(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [projects]);

  useEffect(() => {
    if (projects) {
      const project = projects.find(project => project.projectRefId === projectRefId);
      if (!project) {
        console.error(`invalid project ${projectRefId}`);
        return;
      }
      /* check if project ref id is not changed */
      if (currentProject?.projectRefId === project.projectRefId) {
        return;
      }

      /* set project and reload bundle */
      setCurrentProject(project);
    }
  }, [projects]);

  useEffect(() => {
    if (sessions && files === null) {
      // fetch files from every session using promise all
      const promises = sessions.map(session => {
        return fetchFiles(session.projectId, session.sessionId);
      });

      Promise.all(promises).then(files => {
        setFiles(files.flat());
      });
    }
  }, [sessions, files]);

  useEffect(() => {
    /* first fetch data */
    fetchData();

    /* fetch data every 30s */
    const interval = setInterval(() => {
      fetchData();
    }, 30000);

    return () => {
      clearInterval(interval);
    };
  }, [currentProject]);

  const createSession = (form: CreateSessionFormType) => {
    if (!currentProject?.projectId) {
      return;
    }

    API.post('labcloud-api', `projects/${currentProject.projectId}/sessions`, {
      body: {
        subjectId: form.subjectId,
        deviceId: form.deviceId,
        configId: form.configId,
        condition: form.condition,
        technician: form.technician,
        note: form.note
      }
    }).then(res => {
      const session = res['session'] as SessionType;
      if (sessions) {
        setSessions([...sessions, session]);
      }

      const sessionRefId = session.sessionRefId;
      navigate(`/projects/${projectRefId}/sessions/${sessionRefId}/streaming`);
    });
  };

  const downloadFile = (session: SessionType | null) => {
    if (!session) {
      console.error('invalid session', session);
      return;
    }

    const fileId = files?.filter(file => file.sessionId === session.sessionId)?.[0]?.fileId;
    if (!fileId) {
      console.log('invalid file id', fileId);
      return;
    }

    API.get('labcloud-api', `files/${fileId}`, {}).then(res => {
      const { file, signedUrl } = res;
      window.open(signedUrl, '_blank', 'noreferrer');
    });
  };

  const openDetailsModal = (session: SessionType | null) => {
    if (!session) return;
    setCurrentSession(session);
    onDetailsModalOpen();
  };

  return (
    <Navbar userName={user.username ?? ''} organizationName='BCI Lab'>
      <ProjectSidebar projectName={currentProject?.name ?? ''} projectRefId={currentProject?.projectRefId ?? ''}>
        <BodyLayout>
          <VStack align='flex-end' mb={5}>
            <Button colorScheme='teal' onClick={onOpen}>
              + Session
            </Button>
          </VStack>
          {isLoading && <LoadingBox></LoadingBox>}
          {!isLoading && subjects && devices && configs && sessions && (
            <>
              <CreateSessionForm
                subjects={subjects}
                devices={devices}
                configs={configs}
                onSubmit={createSession}
                onClose={onClose}
                isOpen={isOpen}
              ></CreateSessionForm>
              {currentSession && (
                <SessionDetailsModal
                  session={currentSession}
                  device={devices?.filter(device => device.deviceId === currentSession.deviceId)[0]}
                  config={configs?.filter(config => config.configId === currentSession.configId)[0]}
                  isOpen={isDetailsModalOpen}
                  onClose={onDetailsModalClose}
                ></SessionDetailsModal>
              )}
              <SessionTable
                sessions={sessions}
                subjects={subjects}
                devices={devices}
                files={files ?? []}
                onRowClick={openDetailsModal}
                onDownloadButtonClick={downloadFile}
              ></SessionTable>
            </>
          )}
        </BodyLayout>
      </ProjectSidebar>
    </Navbar>
  );
};

export default Session;
