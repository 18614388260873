import { useAuthenticator } from '@aws-amplify/ui-react';
import { Box, Button, Container, Flex, HStack, Text, VStack } from '@chakra-ui/react';
import { API } from 'aws-amplify';
import { Buffer } from 'buffer';
import React, { useEffect, useRef, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { BodyLayout } from '../components/BodyLayout';
import { Navbar } from '../components/Navbar';
import { ProjectSidebar } from '../components/project/ProjectSidebar';
import { Plotter } from '../components/session/Plotter';
import useDebugSamplingRate from '../hooks/useDebugSamplingRate';
import useRenderGraph from '../hooks/usePlot';
import useProject from '../hooks/useProject';
import useSerial from '../hooks/useSerial';
import { convertDigitalToPhysical, convertSamplesToSignals, transform, transpose } from '../libs';
import { deviceConfig } from '../libs/drivers/unicorn';

type StreamDataType = {
  stamp: number;
  no: number;
  data: string;
  trigs?: number[][];
};

type PageRefType = { projectId: string | null; sessionId: string | null };

type SerialRefType = {
  plotDataArray: number[][];
  streamU8ChunkArray: Uint8Array[];
  currentIdx: number;
  startIdx: number;
  startTimestamp: number | null;
};

type RecordDataRefType = {
  recordingState: 'init' | 'starting' | 'recording' | 'recorded';
  currentIdx?: number;
  /* sample index when start recording */
  startIdx?: number;
  /* timestamp when start recording */
  startTimestamp?: number;
  annotations: { annotationId: number; timestamp: number; idx: number; title: string }[];
};

type StreamDataRefType = {
  chunkIdx: number;
  chunks: { [no: number]: StreamDataType };
};

const Stream: React.FC = () => {
  const { projectRefId, sessionRefId } = useParams();
  const navigate = useNavigate();
  const { user } = useAuthenticator();

  if (!projectRefId || !sessionRefId) return <></>;

  const { projects, annotations, setProjects, currentProject, setCurrentProject, currentSession, setCurrentSession, reload, setReload } =
    useProject(projectRefId, sessionRefId);

  const serialRef = useRef<SerialRefType>({
    plotDataArray: [],
    streamU8ChunkArray: [],
    currentIdx: 0,
    startIdx: 0,
    startTimestamp: null
  });

  const readCallback = (data: Uint8Array[]) => {
    // console.log('callback:', data);

    const outputArray = data;
    let plotDataArray: number[][] = serialRef.current.plotDataArray;
    let currentIdx = serialRef.current.currentIdx;

    if (currentIdx === 0) {
      console.log('set recording state', recordingState);
      setRecordingState('connected');
    }

    for (let s = 0; s < outputArray.length; s += 1) {
      // later, replace with down-sampling ratio const
      const tmpSampleData = transform(outputArray[s]);
      if (currentIdx % 5 == 0) {
        plotDataArray.push(tmpSampleData.map((s, ch) => deviceConfig.Displays[ch].preprocessingPipeline.process(s)));
      }
      currentIdx += 1;
    }

    serialRef.current.currentIdx = currentIdx;
    serialRef.current.streamU8ChunkArray = [...serialRef.current.streamU8ChunkArray, ...outputArray];

    // tmp config
    const plotDuration = 5;
    const samplingRate = 250;
    const downSamplingRatio = 5;
    const plotRate = samplingRate / downSamplingRatio;

    serialRef.current.plotDataArray = plotDataArray.slice(-plotRate * plotDuration);
    plotDataArray = serialRef.current.plotDataArray;

    /* update amplitude */
    if (currentIdx % 125 == 0 && plotDataArray.length > 0) {
      const tmpSignals = convertSamplesToSignals(deviceConfig, plotDataArray);
      const vals = deviceConfig.Displays.map((display, ch) => {
        switch (display.mode) {
          case 'mean':
            return tmpSignals[ch].reduce((sum: number, s: number) => sum + s, 0) / tmpSignals[ch].length;
          case 'diff':
            return Math.max(...tmpSignals[ch]) - Math.min(...tmpSignals[ch]);
          case 'max':
            return Math.max(...tmpSignals[ch]);
          default:
            return 0;
        }
      }).map((val, ch) => convertDigitalToPhysical(deviceConfig.Channels[ch], val));
      setPlotAmplitudes(vals);
    }

    /* update data plot data state */
    plotDataRef.current.dataArray = plotDataArray;

    /* for sampling rate checking */
    recordDataRef.current.currentIdx = currentIdx;

    // console.log(recordDataRef.current.recordingState, streamU8ChunkArray.length);
    if (recordDataRef.current.recordingState === 'starting') {
      const startIdx = currentIdx;
      const startTimestamp = new Date().getTime();
      /* clear stream data array */
      serialRef.current.streamU8ChunkArray = [];
      recordDataRef.current.recordingState = 'recording';
      recordDataRef.current.startIdx = startIdx;
      recordDataRef.current.startTimestamp = startTimestamp;
    } else if (recordDataRef.current.recordingState === 'recording') {
      if (serialRef.current.streamU8ChunkArray.length >= 250) {
        const tmpStreamDataArray = serialRef.current.streamU8ChunkArray.slice(0, 250);
        serialRef.current.streamU8ChunkArray = serialRef.current.streamU8ChunkArray.slice(250);

        const no = streamDataRef.current.chunkIdx;
        const annotations = recordDataRef.current.annotations.slice();
        /* clear annotation stage */
        recordDataRef.current.annotations = [];
        /* map to api annotation */
        const trigs = annotations.map(annotation => {
          const idx = annotation.annotationId;
          const onset = annotation.idx / 250;
          return [onset, idx];
        });
        const streamData = {
          no: no,
          stamp: new Date().getTime(),
          data: Buffer.from(transpose(deviceConfig, tmpStreamDataArray)).toString('base64'),
          trigs: trigs
        };
        streamDataRef.current.chunks[no] = streamData;
        streamDataRef.current.chunkIdx += 1;

        console.log(`stream ${no}`);
        postStream(pageRef.current.projectId ?? '', pageRef.current.sessionId ?? '', streamData)
          .then(res => {
            const { no } = res;
            /* remove success no from tmp */
            delete streamDataRef.current.chunks[no];
            console.log('stream:', no);
          })
          .catch(err => {
            console.error(err);
          });
      }
    }
  };
  const { connect, status, serialWrite } = useSerial({ readCallback });

  /* recording */
  const [recordingState, setRecordingState] = useState<'disconnected' | 'connected' | 'recording'>('disconnected');

  /* plot */

  const pageRef = useRef<PageRefType>({
    projectId: null,
    sessionId: null
  });

  const recordDataRef = useRef<RecordDataRefType>({
    recordingState: 'init',
    annotations: []
  });
  const streamDataRef = useRef<StreamDataRefType>({
    chunkIdx: 0,
    chunks: {}
  });

  const { plotDataRef, graphWidth, plotSignals, plotAnnotations, plotAmplitudes, setPlotAmplitudes, plotBoxRef } = useRenderGraph();
  const { samplingRate } = useDebugSamplingRate(recordDataRef);

  /* api */
  const startSession = async (projectId: string, sessionId: string) => {
    return API.post('labcloud-api', `projects/${projectId}/sessions/${sessionId}/start`, {});
  };

  const stopSession = async (projectId: string, sessionId: string) => {
    return API.post('labcloud-api', `projects/${projectId}/sessions/${sessionId}/stop`, {});
  };

  const postStream = async (projectId: string, sessionId: string, streamData: StreamDataType) => {
    return API.post('labcloud-api', `projects/${projectId}/sessions/${sessionId}/stream`, {
      body: streamData
    });
  };

  /* use effect */
  useEffect(() => {
    if (!currentSession) return;
    pageRef.current = {
      projectId: currentSession.projectId,
      sessionId: currentSession.sessionId
    };
  }, [currentSession]);

  /* auto start after connect */
  useEffect(() => {
    if (status === 'connected') {
      /* send serial start command */
      setTimeout(sendSerialStop, 500);
      setTimeout(sendSerialStart, 1000);
      setTimeout(sendSerialStart, 1500);
    }
  }, [status]);

  /* processing functions */
  const sendSerialStart = () => serialWrite(Uint8Array.from([0x61, 0x7c, 0x87]));
  const sendSerialStop = () => serialWrite(Uint8Array.from([0x63, 0x5c, 0xc5]));

  /* button callback */
  const startRecord = () => {
    console.log('starting session');
    if (!currentSession?.projectId) {
      console.error('invalid session');
      return;
    }

    startSession(currentSession.projectId, currentSession.sessionId)
      .then(() => {
        console.log('started session');
        recordDataRef.current.recordingState = 'starting';
        /* update ui */
        setRecordingState('recording');
      })
      .catch(err => {
        console.error(err);
      });
  };

  const stopRecord = () => {
    console.log('stopping session');
    if (!currentSession?.projectId) {
      console.error('invalid session');
      return;
    }

    stopSession(currentSession.projectId, currentSession.sessionId)
      .then(() => {
        console.log('stopped session');
        setRecordingState('connected');
        sendSerialStop();
        console.log('navigate');
        navigate(`/projects/${projectRefId}/sessions`);
      })
      .catch(err => {
        console.error(err);
      });
  };

  const annotate = (annotaionId: number) => {
    if (!annotations) {
      console.error('invalid annotations');
      return;
    }

    if (recordDataRef.current.recordingState == 'recording') {
      /* 
      1. idx -> find diff with start idx
      2. convert diff idx to time (/ sampling rate)
      3. add to start timestamp */
      if (recordDataRef.current?.currentIdx && recordDataRef.current?.startIdx && recordDataRef.current?.startTimestamp) {
        const idx = recordDataRef.current.currentIdx - recordDataRef.current?.startIdx;
        // later, use sampling rate
        const timestamp = recordDataRef.current?.startTimestamp + (idx * 1000) / 250;
        const title = annotations.find(annotation => parseInt(annotation.annotationRefId) === annotaionId)?.title ?? '';
        const annotation = {
          annotationId: annotaionId,
          idx: idx,
          timestamp: timestamp,
          title: title
        };
        recordDataRef.current.annotations.push(annotation);
        plotDataRef.current.annotations.push(annotation);
      }
    }
  };

  return (
    <Navbar userName={user.username ?? ''} organizationName='BCI Lab'>
      <ProjectSidebar projectName={currentProject?.name ?? ''} projectRefId={currentProject?.projectRefId ?? ''}>
        <BodyLayout>
          <HStack>
            <Button onClick={connect} colorScheme='blue' disabled={recordingState !== 'disconnected'}>
              Connect
            </Button>
            <Button
              onClick={recordingState !== 'recording' ? startRecord : stopRecord}
              colorScheme={recordingState !== 'recording' ? 'teal' : 'red'}
              disabled={recordingState === 'disconnected'}
            >
              {recordingState !== 'recording' ? 'Start record' : 'Stop record'}
            </Button>
            <Text>Status:{recordingState}</Text>
            <Text>Sampling rate:{samplingRate}</Text>
          </HStack>
          <Container maxW='100%' mt={3} p={0} ref={plotBoxRef}>
            <Flex color='white'>
              <Box flex='1' bg='tomato'>
                <Plotter
                  outerWidth={graphWidth - 200}
                  deviceConfig={deviceConfig}
                  signals={plotSignals}
                  annotations={plotAnnotations}
                  amplitudes={plotAmplitudes}
                ></Plotter>
              </Box>
              <Box w='200px' bg='gray.100'>
                <Text alignSelf='center' paddingBottom='4' color='gray.800'>
                  Trigger Events
                </Text>
                <VStack alignSelf='center' spacing={1}>
                  {annotations &&
                    annotations.map(annotation => (
                      <Button
                        key={annotation.annotationId}
                        colorScheme='blue'
                        disabled={recordingState !== 'recording'}
                        onClick={() => annotate(parseInt(annotation.annotationRefId))}
                      >
                        {annotation.title}
                      </Button>
                    ))}
                </VStack>
              </Box>
            </Flex>
          </Container>
        </BodyLayout>
      </ProjectSidebar>
    </Navbar>
  );
};
export default Stream;
