export const parseDateTime = (isoDate: string) => {
  const date = new Date(isoDate);
  const formattedDate = date.toLocaleString('en-US', {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
    timeZone: 'Asia/Bangkok'
  });
  return formattedDate;
};
