import { Pipeline } from './filter';
import { LowLevelDataConvert, LowLevelDataSize, LowLevelDataType } from './lowLevel';

export type Channel = {
  idx: number;
  title: string;
  dataType: LowLevelDataType;
  samples: number;
  digitalMinimum: number;
  digitalMaximum: number;
  physicalMinimum: number;
  physicalMaximum: number;
  physicalDimension: string;
  prefiltering?: string;
  transducer?: string;
};

export type Display = {
  samples: number;
  preprocessingPipeline: Pipeline;
  digitalDisplayMinimum: number;
  digitalDisplayMaximum: number;
  mode: 'mean' | 'diff' | 'max';
};

export type DeviceConfig = {
  Channels: Channel[];
  Displays: Display[];
};

/**
 * Transforms a sample from a device configuration and raw data.
 * @param deviceConfig - The device configuration that describes the layout of the raw data.
 * @param buf - The raw data to be transformed into an array of data points.
 * @returns An array of data points, one for each channel in the device configuration.
 */
export const transformSample: (config: DeviceConfig, buf: Uint8Array) => number[] = (deviceConfig: DeviceConfig, buf: Uint8Array) => {
  let offset = 0;
  const dataArray: number[] = new Array(deviceConfig.Channels.length);
  for (const i in deviceConfig.Channels) {
    const channel = deviceConfig.Channels[i];
    dataArray[i] = LowLevelDataConvert[channel.dataType](buf.subarray(offset, offset + LowLevelDataSize[channel.dataType]));
    offset += LowLevelDataSize[channel.dataType];
  }
  return dataArray;
};

export const transpose = (config: DeviceConfig, data: Uint8Array[]): Uint8Array => {
  console.log('transpose', data[0].length);
  const output = new Uint8Array(data.length * data[0].length);
  let sampleIdx = 0;
  let channelIdx = 0;
  for (const ch of config.Channels) {
    const l = LowLevelDataSize[ch.dataType];
    for (const sample of data) {
      output.set(sample.subarray(channelIdx, channelIdx + l), sampleIdx);
      sampleIdx += l;
    }
    channelIdx += l;
  }
  return output;
};

export const convertDigitalToPhysical = (channel: Channel, digitalValue: number) => {
  return (
    ((digitalValue - channel.digitalMinimum) / (channel.digitalMaximum - channel.digitalMinimum)) *
      (channel.physicalMaximum - channel.physicalMinimum) +
    channel.physicalMinimum
  );
};

export const convertPhysicalToDigital = (channel: Channel, physicalValue: number) => {
  return (
    ((physicalValue - channel.physicalMinimum) / (channel.physicalMaximum - channel.physicalMinimum)) *
      (channel.digitalMaximum - channel.digitalMinimum) +
    channel.digitalMinimum
  );
};

export const convertSamplesToSignals = (deviceConfig: DeviceConfig, samples: number[][]) => {
  if (samples?.length == 0) return new Array(deviceConfig.Channels.length).fill(null).map(() => []);
  if (deviceConfig.Channels.length !== samples[0].length) throw new Error('Samples has invalid channel length');

  const signals = new Array(deviceConfig.Channels.length).fill(null).map(() => new Array(samples.length).fill(null));
  for (let s = 0; s < samples.length; s += 1) {
    for (let ch = 0; ch < deviceConfig.Channels.length; ch += 1) {
      signals[ch][s] = samples[s][ch];
    }
  }
  return signals;
};

export const getSignalPhysicalAmplitudes = (config: DeviceConfig, samples: number[][]) => {
  if (samples.length == 0) {
    console.error('no samples');
    return [];
  }

  const signals = new Array(samples[0].length).fill(null).map(() => new Array(samples.length).fill(null));
  for (let s = 0; s < samples.length; s += 1) {
    for (let ch = 0; ch < samples[0].length; ch += 1) {
      signals[ch][s] = samples[s][ch];
    }
  }

  return signals
    .map(signal => Math.max(...signal) - Math.min(...signal))
    .map(
      (amplitude, ch) =>
        (amplitude * (config.Channels[ch].physicalMaximum - config.Channels[ch].physicalMinimum)) /
        (config.Channels[ch].digitalMaximum - config.Channels[ch].digitalMinimum)
    );
};

export const getSignalPhysicalMean = (config: DeviceConfig, samples: number[][]) => {
  if (samples.length == 0) {
    console.error('no samples');
    return [];
  }

  const signals = new Array(samples[0].length).fill(null).map(() => new Array(samples.length).fill(null));
  for (let s = 0; s < samples.length; s += 1) {
    for (let ch = 0; ch < samples[0].length; ch += 1) {
      signals[ch][s] = samples[s][ch];
    }
  }

  return signals
    .map(signal => signal.reduce((mean, s) => mean + s, 0))
    .map(
      (amplitude, ch) =>
        (amplitude * (config.Channels[ch].physicalMaximum - config.Channels[ch].physicalMinimum)) /
        (config.Channels[ch].digitalMaximum - config.Channels[ch].digitalMinimum)
    );
};

export const getSignalPhysicalAmplitudeStrings = (config: DeviceConfig, samples: number[][]) => {
  return getSignalPhysicalAmplitudes(config, samples).map(
    (physicalAmplitude, ch) => `${physicalAmplitude.toFixed(2)} ${config.Channels[ch].physicalDimension}`
  );
};
