import { useAuthenticator } from '@aws-amplify/ui-react';
import { Box, Text } from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchProjects } from '../api/api';
import { BodyLayout } from '../components/BodyLayout';
import LoadingBox from '../components/LoadingBox';
import { Navbar } from '../components/Navbar';
import { TemplateConfigTable } from '../components/templateConfig/TemplateConfigTable';
import { ProjectContext, ProjectContextType } from '../stores/ProjectContext';
import { ConfigType, DeviceType } from '../types/types';

const OverviewDevice: React.FC = () => {
  const navigate = useNavigate();
  const { deviceId } = useParams();
  const { user } = useAuthenticator();

  const { projects, setProjects, devices, setDevices, configs, setConfigs } = useContext<ProjectContextType>(ProjectContext);
  const [currentDevice, setCurrentDevice] = useState<DeviceType | null>(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    if (!devices) {
      /* load projects */
      setIsLoading(true);
      fetchProjects()
        .then(res => {
          const { projects, devices } = res;
          /* set projects */
          setProjects(projects);
          setDevices(devices);
        })
        .catch(err => {
          setError(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [devices]);

  useEffect(() => {
    if (devices) {
      const device = devices.find(device => device.deviceId === deviceId);
      if (!device) {
        console.error(`invalid device ${deviceId}`);
        setError(new Error(`invalid device ${deviceId}`));
        return;
      }

      /* check if project ref id is not changed */
      if (currentDevice?.deviceId === device.deviceId) {
        return;
      }

      /* set project and reload bundle */
      setCurrentDevice(device);
    }
  }, [devices]);

  const navigateToConfig = (config: ConfigType | null) => {
    if (!config) {
      console.error('invalid config', config);
      return;
    }
    const configId = config.configId;
    navigate(`/devices/${deviceId}/configs/${configId}`);
  };

  return (
    <Navbar userName={user.username ?? ''} organizationName='BCI Lab'>
      <BodyLayout>
        {isLoading && <LoadingBox></LoadingBox>}
        {!isLoading && configs && currentDevice && (
          <>
            <Box mt={5}>
              <Text align='start'>Device ID: {currentDevice.deviceId}</Text>
              <Text align='start'>Band: {currentDevice.brand}</Text>
              <Text align='start'>Model: {currentDevice.model}</Text>
              <Text align='start'>Serial Number: {currentDevice.sn}</Text>
            </Box>
            <Box mt={5}>
              <TemplateConfigTable configs={configs}></TemplateConfigTable>
            </Box>
          </>
        )}
      </BodyLayout>
    </Navbar>
  );
};
export default OverviewDevice;
