import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text, VStack } from '@chakra-ui/react';
import { SubjectType } from '../../types/types';

type SubjectDetailsModalProps = {
  subject: SubjectType;
  isOpen: boolean;
  onClose: () => void;
};

const SubjectDetailsModal: React.FC<SubjectDetailsModalProps> = ({ subject, isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size='2xl'>
      <ModalOverlay />
      <ModalContent bg='gray.100'>
        <ModalHeader>Subject Details</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={2} mt={16} my={16} ml='20%' mr='20%' align='start'>
            <Text>
              <b>ID:</b> {subject.subjectRefId}
            </Text>
            <Text>
              <b>Label:</b> {subject.label}
            </Text>
            <Text>
              <b>Birthdate:</b> {subject.birthdate}
            </Text>
            <Text>
              <b>Gender:</b> {subject.gender === 'not-specified' ? 'Not Specified' : subject.gender === 'male' ? 'Male' : 'Female'}
            </Text>
            <Text>
              <b>Handedness:</b>{' '}
              {subject.handedness === 'not-specified'
                ? 'Not Specified'
                : subject.handedness === 'left'
                ? 'Left'
                : subject.handedness === 'right'
                ? 'Right'
                : 'Ambidextrous'}
            </Text>
            {subject.note && (
              <Text>
                <b>Note:</b> {subject.note}
              </Text>
            )}
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default SubjectDetailsModal;
