import { Box, Flex, Icon, Link, Text } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { IconType } from 'react-icons';
import { NavLink as RouterLink } from 'react-router-dom';

export type LinkItemType = {
  name: string;
  icon: IconType;
  to: string;
};

// const linkItems: LinkItemType[] = [
//   { name: 'Subject', icon: BsPerson, to: '/subjects' },
//   { name: 'Session', icon: FiActivity, to: '/sessions' },
//   { name: 'Annotation', icon: HiAnnotation, to: '/annotations' }
// ];

type SidebarProps = {
  children: ReactNode;
  title: string;
  linkItems: LinkItemType[];
};

export const Sidebar: React.FC<SidebarProps> = ({ children, title, linkItems }) => {
  return (
    <Flex color='gray.900' minH='100vh'>
      <Box w='200px' pt={10} bg='gray.200' borderRight='1px' borderRightColor='gray.200'>
        <Flex minH='20' alignItems='center' mx='8' justifyContent='space-between'>
          <Text fontSize='xl' fontWeight='semibold'>
            {title}
          </Text>
        </Flex>
        {linkItems.map(link => (
          <NavItem key={link.name} icon={link.icon} to={link.to}>
            {link.name}
          </NavItem>
        ))}
      </Box>
      {children}
    </Flex>
  );
};

type NavItemProps = {
  icon: IconType;
  to: string;
  children: ReactNode;
};

const NavItem = ({ icon, to, children }: NavItemProps) => {
  return (
    <Link as={RouterLink} to={to} style={{ textDecoration: 'none' }} _focus={{ boxShadow: 'none' }}>
      <Flex
        align='center'
        p='4'
        mx='4'
        borderRadius='lg'
        role='group'
        cursor='pointer'
        _hover={{
          bg: 'cyan.400',
          color: 'white'
        }}
      >
        {icon && (
          <Icon
            mr='4'
            fontSize='16'
            _groupHover={{
              color: 'white'
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Link>
  );
};
