import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Input,
  Text
} from '@chakra-ui/react';
import { useState } from 'react';
import { AnnotationType } from '../../types/types';

export type CreateAnnotationFormType = Omit<AnnotationType, 'organizationId' | 'projectId' | 'annotationId' | 'annotationRefId'>;

type CreateAnnotationFormProps = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (form: CreateAnnotationFormType) => void;
};

export const CreateAnnotationForm: React.FC<CreateAnnotationFormProps> = ({ isOpen, onClose, onSubmit }) => {
  const [titleError, setTitleError] = useState<string | null>(null);
  const [messageError, setMessageError] = useState<string | null>(null);

  const [form, setForm] = useState<CreateAnnotationFormType>({
    title: '',
    message: ''
  });

  const handleValueChange = (e: React.FormEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.currentTarget;
    setForm({ ...form, [name]: value });
  };

  const validateForm = () => {
    let ret = true;

    if (!form.title.trim()) {
      setTitleError('Title cannot be empty.');
      ret = ret && false;
    } else {
      setTitleError(null);
    }

    if (!form.message.trim()) {
      setMessageError('Message cannot be empty.');
      ret = ret && false;
    } else {
      setMessageError(null);
    }

    return ret;
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (validateForm()) {
      onSubmit(form);
      onClose();
    }
  };

  return (
    <Drawer isOpen={isOpen} placement='right' onClose={onClose} size='md'>
      <form onSubmit={handleSubmit}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader marginBottom='10' alignSelf='center'>
            <Text fontSize='3xl' as='b'>
              Create annotation
            </Text>
          </DrawerHeader>

          <DrawerBody>
            <Text color='blue.900' as='b'>
              Title
            </Text>
            <Input marginTop='2' marginBottom='4' name='title' onChange={handleValueChange} isInvalid={titleError !== null} />
            <Text color='blue.900' as='b'>
              Message
            </Text>
            <Input marginTop='2' marginBottom='4' name='message' onChange={handleValueChange} isInvalid={messageError !== null} />
          </DrawerBody>

          <DrawerFooter>
            <Button variant='outline' mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme='blue' type='submit'>
              Create
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </form>
    </Drawer>
  );
};
