import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Input,
  Select,
  Text,
  Textarea
} from '@chakra-ui/react';
import { useState } from 'react';
import { SubjectType } from '../../types/types';

export type CreateSubjectFormType = Omit<SubjectType, 'organizationId' | 'projectId' | 'subjectId' | 'subjectRefId'>;

type CreateSubjectFormProps = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (form: CreateSubjectFormType) => void;
};

export const CreateSubjectForm: React.FC<CreateSubjectFormProps> = ({ isOpen, onClose, onSubmit }) => {
  const [subjectLabelError, setSubjectLabelError] = useState<string | null>(null);
  const [birthdateError, setBirthdateError] = useState<string | null>(null);

  const [form, setForm] = useState<CreateSubjectFormType>({
    label: '',
    gender: 'not-specified',
    handedness: 'not-specified',
    birthdate: '',
    note: ''
  });

  const handleInputChange = (event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setForm(prevState => ({ ...prevState, [name]: value }));
  };

  const validateForm = () => {
    let ret = true;

    if (!form.label.trim()) {
      setSubjectLabelError('Subject label cannot be empty.');
      ret = ret && false;
    } else {
      setSubjectLabelError(null);
    }

    if (!form.birthdate.trim()) {
      setBirthdateError('Birthdate cannot be empty.');
      ret = ret && false;
    } else {
      setBirthdateError(null);
    }

    return ret;
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (validateForm()) {
      onSubmit(form);
      onClose();
    }
  };

  return (
    <Drawer isOpen={isOpen} placement='right' onClose={onClose} size='md'>
      <form onSubmit={handleSubmit}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader marginBottom='10' alignSelf='center'>
            <Text fontSize='3xl' as='b'>
              Create subject
            </Text>
          </DrawerHeader>

          <DrawerBody>
            <Text color='blue.900' as='b'>
              Subject label
            </Text>
            <Input
              marginTop='2'
              marginBottom='4'
              name='label'
              value={form.label}
              onChange={handleInputChange}
              isInvalid={subjectLabelError !== null}
            />
            <Text color='blue.900' as='b'>
              Birthdate
            </Text>
            <Input
              marginTop='2'
              marginBottom='4'
              type='date'
              name='birthdate'
              value={form.birthdate}
              onChange={handleInputChange}
              isInvalid={birthdateError !== null}
            />
            <Text color='blue.900' as='b'>
              Gender
            </Text>
            <Select marginTop='2' marginBottom='4' name='gender' value={form.gender} onChange={handleInputChange}>
              <option value='not-specified'>Not Specified</option>
              <option value='male'>Male</option>
              <option value='female'>Female</option>
            </Select>
            <Text color='blue.900' as='b'>
              Handedness
            </Text>
            <Select marginTop='2' marginBottom='4' name='handedness' value={form.handedness} onChange={handleInputChange}>
              <option value='not-specified'>Not Specified</option>
              <option value='left'>Left</option>
              <option value='right'>Right</option>
              <option value='ambidextrous'>Ambidextrous</option>
            </Select>
            <Text color='blue.900' as='b'>
              Note
            </Text>
            <Textarea marginTop='2' marginBottom='4' name='note' value={form.note} onChange={handleInputChange} />
          </DrawerBody>

          <DrawerFooter>
            <Button variant='outline' mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme='blue' type='submit'>
              Create
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </form>
    </Drawer>
  );
};
