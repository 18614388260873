import { useContext, useEffect, useState } from 'react';
import { fetchProjectBundle, fetchProjects } from '../api/api';
import { ProjectContext, ProjectContextType } from '../stores/ProjectContext';
import { SessionType } from '../types/types';

const useProject = (projectRefId: string, sessionRefId: string) => {
  const {
    projects,
    setProjects,
    currentProject,
    setCurrentProject,
    subjects,
    setSubjects,
    sessions,
    setSessions,
    annotations,
    setAnnotations
  } = useContext<ProjectContextType>(ProjectContext);
  const [currentSession, setCurrentSession] = useState<SessionType | null>(null);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    if (!projects) {
      /* load projects */
      fetchProjects().then(res => {
        const { projects } = res;
        /* clear current project and bundle */
        setSubjects(null);
        setSessions(null);
        setAnnotations(null);
        setCurrentProject(null);
        /* set projects */
        setProjects(projects);
      });
      return;
    }

    const project = projects.find(project => project.projectRefId === projectRefId);
    if (!project) {
      console.error(`invalid project ${projectRefId}`);
      return;
    }

    fetchProjectBundle(project.projectId).then(res => {
      const { subjects, sessions, annotations } = res;
      setSubjects(subjects);
      setSessions(sessions);
      setAnnotations(annotations);

      const session = sessions.find(session => session.sessionRefId === sessionRefId);

      if (!session) {
        console.error('invalid session');
        return;
      }

      // const pageRef = {
      //   projectId: session.projectId,
      //   sessionId: session.sessionId
      // };

      setCurrentSession(session);
      setReload(false);

      console.log('done loading');
      console.log('project:', session.projectId);
      console.log('session:', session.sessionId);
      console.log('annotations', annotations);
    });

    /* set project and reload bundle */
    setCurrentProject(project);
  }, [projects, projectRefId]);

  return {
    projects,
    annotations,
    setProjects,
    currentProject,
    setCurrentProject,
    currentSession,
    setCurrentSession,
    reload,
    setReload
  };
};

export default useProject;
