import { useRef, useState } from 'react';
import { useEffectOnce } from 'usehooks-ts';

type PlotDataRefType = {
  dataArray: number[][];
  annotations: { annotationId: number; timestamp: number; idx: number; title: string }[];
};

const useRenderGraph = () => {
  const [graphWidth, setGraphWidth] = useState<number>(500);
  const [plotSignals, setPlotSignals] = useState<{ x: number; y: number }[][]>([]);
  const [plotAnnotations, setPlotAnnotations] = useState<{ x: number; title: string }[]>([]);
  const [plotAmplitudes, setPlotAmplitudes] = useState<number[]>([]);

  const plotDataRef = useRef<PlotDataRefType>({
    dataArray: [],
    annotations: []
  });
  const plotBoxRef = useRef<HTMLHeadingElement>(null);

  /* setup */
  useEffectOnce(() => {
    console.log('setup');
    window.addEventListener('resize', resizeGraph);

    resizeGraph();
    const renderIntervalId = setInterval(renderGraph, 50);

    return () => {
      console.log('clean up');
      window.removeEventListener('resize', resizeGraph);
      clearInterval(renderIntervalId);
    };
  });

  const renderGraph = () => {
    const newPlotSignals: { x: number; y: number }[][] = new Array(16).fill(null).map(_ => {
      /* 50 sps x 5 seconds */
      const duration = 5;
      const sps = 50;
      return new Array(duration * sps).fill(null).map((_, idx) => {
        return {
          x: (idx - duration * sps) / 50,
          y: 0
        };
      });
    });

    /* reformat for plotting */
    const plotDataArray = plotDataRef.current.dataArray;
    for (let s = 0; s < plotDataArray.length; s += 1) {
      for (let ch = 0; ch < plotDataArray[s].length; ch += 1) {
        newPlotSignals[ch][s].y = plotDataArray[s][ch];
      }
    }

    /* set plotting data to react state */
    setPlotSignals(newPlotSignals);

    /* prepare annotations */
    const annotations = plotDataRef.current.annotations;
    const nowTimestamp = new Date().getTime();
    /* filter only annotation within plot range */
    plotDataRef.current.annotations = annotations.filter(annotation => (nowTimestamp - annotation.timestamp) / 1000 <= 5);

    const newPlotAnnotations: { x: number; title: string }[] = [];

    annotations.forEach(annotation => {
      const x = (annotation.timestamp - nowTimestamp) / 1000;
      /* limit to -5 seconds */
      if (x < -5) return;
      newPlotAnnotations.push({
        x: x,
        title: annotation.title
      });
    });
    setPlotAnnotations(newPlotAnnotations);
  };

  const resizeGraph = () => {
    if (plotBoxRef.current?.offsetWidth) setGraphWidth(plotBoxRef.current.offsetWidth);
  };

  return {
    plotDataRef,
    graphWidth,
    plotSignals,
    plotAnnotations,
    plotAmplitudes,
    setPlotAmplitudes,
    plotBoxRef
  };
};

export default useRenderGraph;
