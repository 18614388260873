import { useAuthenticator } from '@aws-amplify/ui-react';
import { Button, useDisclosure, VStack } from '@chakra-ui/react';
import { API } from 'aws-amplify';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchProjects } from '../api/api';
import { BodyLayout } from '../components/BodyLayout';
import LoadingBox from '../components/LoadingBox';
import { Navbar } from '../components/Navbar';
import { CreateProjectForm, CreateProjectFormType } from '../components/project/CreateProjectForm';
import { ProjectTable } from '../components/project/ProjectTable';
import { ProjectContext, ProjectContextType } from '../stores/ProjectContext';
import { ProjectType } from '../types/types';

const Project: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useAuthenticator();

  const { projects, setProjects, devices, setDevices, configs, setConfigs } = useContext<ProjectContextType>(ProjectContext);
  // const [reload, setReload] = useState<boolean>(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    if (!projects) {
      setIsLoading(true);
      fetchProjects()
        .then(res => {
          const { projects, devices } = res;
          setProjects(projects);
          setDevices(devices);
        })
        .catch(err => {
          setError(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [projects]);

  const createProject = (form: CreateProjectFormType) => {
    API.post('labcloud-api', 'projects', {
      body: {
        name: form.name,
        owner: form.owner
      }
    }).then(res => {
      const project = res['project'] as ProjectType;
      if (projects) {
        setProjects([...projects, project]);
      }
    });
  };

  // const setProjectState = (e: React.FormEvent<HTMLSelectElement>) => {
  //   const projectId = e.currentTarget.id;
  //   API.put('labcloud-api', `projects/${projectId}`, {
  //     body: {
  //       state: e.currentTarget.value
  //     }
  //   }).then(() => {
  //     setReload(true);
  //   });
  // };

  const navigateToSession = (project: ProjectType | null) => {
    if (!project) {
      console.error('invalid project', project);
      return;
    }
    const projectRefId = project.projectRefId;
    console.log('navigate');
    navigate(`/projects/${projectRefId}`);
  };

  return (
    <Navbar userName={user.username ?? ''} organizationName='BCI Lab'>
      <BodyLayout>
        <VStack align='flex-end' mb={5}>
          <Button colorScheme='teal' onClick={onOpen}>
            + Project
          </Button>
        </VStack>
        {isLoading && <LoadingBox></LoadingBox>}
        {!isLoading && (
          <>
            <CreateProjectForm onSubmit={createProject} onClose={onClose} isOpen={isOpen}></CreateProjectForm>
            <ProjectTable projects={projects ?? []} onRowClick={navigateToSession}></ProjectTable>
          </>
        )}
      </BodyLayout>
    </Navbar>
  );
};

export default Project;
