import { Button, HStack, Text } from '@chakra-ui/react';
import React from 'react';
import { BodyLayout } from '../components/BodyLayout';
import { Navbar } from '../components/Navbar';
import { ProjectSidebar } from '../components/project/ProjectSidebar';
import useSerial from '../hooks/useSerial';

const Project: React.FC = () => {
  const readCallback = (data: Uint8Array[]) => {
    console.log('callback:', data);
  };
  const { connect, status, serialWrite } = useSerial({ readCallback });

  const sendSerialStart = () => serialWrite(Uint8Array.from([0x61, 0x7c, 0x87]));
  const sendSerialStop = () => serialWrite(Uint8Array.from([0x63, 0x5c, 0xc5]));

  return (
    <Navbar userName='sarawin@highstack.cloud' organizationName='highstack'>
      <ProjectSidebar projectName='Project B' projectRefId='testxxx'>
        <BodyLayout>
          <HStack spacing={2}>
            <Text>{status}</Text>
            <Button colorScheme='teal' onClick={connect}>
              Connect
            </Button>
            <Button colorScheme='teal' onClick={sendSerialStart}>
              Send start
            </Button>
            <Button colorScheme='teal' onClick={sendSerialStop}>
              Send stop
            </Button>
          </HStack>
        </BodyLayout>
      </ProjectSidebar>
    </Navbar>
  );
};

export default Project;
