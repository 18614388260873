import { AmplifyProvider, withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { Amplify, Auth } from 'aws-amplify';
import { Navigate, Route, Routes } from 'react-router-dom';
import './App.css';
import awsExports from './aws-exports';
import Annotation from './pages/Annotation';
import DeviceConfig from './pages/DeviceConfig';
import Logout from './pages/Logout';
import OverviewDevice from './pages/Overview';
import Project from './pages/Project';
import Session from './pages/Session';
import Stream from './pages/Stream';
import Subject from './pages/Subject';
import TemplateConfig from './pages/TemplateConfig';
import TemplateDevice from './pages/TemplateDevice';
import Test from './pages/Test';
import { ProjectProvider } from './stores/ProjectContext';

Amplify.configure(awsExports);

Amplify.configure({
  API: {
    endpoints: [
      {
        name: 'labcloud-api',
        // endpoint: 'https://o0rv1hy6e3.execute-api.ap-southeast-1.amazonaws.com/dev/'
        // endpoint: 'https://rjzp4f3i52.execute-api.ap-southeast-1.amazonaws.com/dev/',
        endpoint: 'https://68wr0bum32.execute-api.ap-southeast-1.amazonaws.com/prod/',
        custom_header: async () => {
          return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` };
        }
      },
      {
        name: 'local',
        endpoint: 'http://localhost:8000'
      }
    ]
  }
});

function App() {
  return (
    <div className='App'>
      <AmplifyProvider>
        <ProjectProvider>
          <Routes>
            <Route path='/' element={<Navigate to='/projects' />} />
            <Route path='/home' element={<Navigate to='/projects' />} />
            <Route path='/projects' element={<Project />} />
            <Route path='/projects/:projectRefId' element={<Navigate to='sessions' />} />
            <Route path='/projects/:projectRefId/sessions' element={<Session />} />
            <Route path='/projects/:projectRefId/subjects' element={<Subject />} />
            <Route path='/projects/:projectRefId/devices' element={<DeviceConfig />} />
            <Route path='/projects/:projectRefId/annotations' element={<Annotation />} />
            <Route path='/devices' element={<TemplateDevice />} />
            <Route path='/devices/:deviceId' element={<Navigate to='device-overview' />} />
            <Route path='/devices/:deviceId/device-overview' element={<OverviewDevice />} />
            <Route path='/devices/:deviceId/configs/:configId' element={<TemplateConfig />} />
            <Route path='/projects/:projectRefId/sessions/:sessionRefId' element={<Navigate to='streaming' />} />
            <Route path='/projects/:projectRefId/sessions/:sessionRefId/streaming' element={<Stream />} />
            <Route path='/logout' element={<Logout />} />
            <Route path='/test' element={<Test />} />
          </Routes>
        </ProjectProvider>
      </AmplifyProvider>
    </div>
  );
}

// export default App;
export default withAuthenticator(App);
