import { Table, TableContainer, Tbody, Td, Th, Thead, Tr, useColorModeValue } from '@chakra-ui/react';
import { ConfigType } from '../../types/types';

type ConfigTableProps = {
  configs: ConfigType[];
  onRowClick?: (config: ConfigType | null) => void;
};

export const ConfigTable: React.FC<ConfigTableProps> = ({ configs, onRowClick }) => {
  const handleRowClick = (e: React.MouseEvent<HTMLTableRowElement>) => {
    e.stopPropagation();
    if (onRowClick) {
      const configId = e.currentTarget.id;
      onRowClick(configs.find(config => config.configId == configId) ?? null);
    }
  };

  return (
    <TableContainer>
      <Table variant='simple'>
        <Thead bg={useColorModeValue('gray.300', 'gray.900')}>
          <Tr>
            {/* <Th>Channel</Th>
            <Th>Channel Name</Th>
            <Th>Sensor Type</Th>
            <Th>Sampling rate</Th>
            <Th>lowpass Filter</Th>
            <Th>Highpass Filter</Th>
            <Th>Notch Filter</Th> */}
            <Th>ID</Th>
            <Th>Note</Th>
          </Tr>
        </Thead>
        <Tbody>
          {configs.map(config => {
            return (
              <Tr
                key={config.configId}
                id={config.configId}
                onClick={handleRowClick}
                cursor='pointer'
                borderRadius='3xl'
                _hover={{ bg: 'cyan.300', color: 'white', borderRadius: '3xl' }}
              >
                {/* <Td>{config.configValue.channel}</Td>
                <Td>{config.configValue.chanName}</Td>
                <Td>{config.configValue.typSensor}</Td>
                <Td>{config.configValue.samplRate}</Td>
                <Td>{config.configValue.filLP}</Td>
                <Td>{config.configValue.filLP}</Td>
                <Td>{config.configValue.filNotch}</Td> */}
                <Td>{config.configId}</Td>
                <Td>{config.note}</Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
};
