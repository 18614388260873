import { Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue } from '@chakra-ui/react';
import { ProjectType } from '../../types/types';

type ProjectTableProps = {
  projects: ProjectType[];
  onRowClick?: (project: ProjectType | null) => void;
};

export const ProjectTable: React.FC<ProjectTableProps> = ({ projects, onRowClick }) => {
  const handleRowClick = (e: React.MouseEvent<HTMLTableRowElement>) => {
    e.stopPropagation();
    if (onRowClick) {
      const projectRefId = e.currentTarget.id;
      onRowClick(projects.find(project => project.projectRefId == projectRefId) ?? null);
    }
  };

  return (
    <TableContainer>
      <Table variant='simple'>
        <Thead>
          <Tr bg={useColorModeValue('gray.300', 'gray.900')}>
            <Th>
              <Text>ID</Text>
            </Th>
            <Th>
              <Text>Project Name</Text>
            </Th>
            <Th>
              <Text>Status</Text>
            </Th>
            <Th>
              <Text>Owner</Text>
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {projects.map(project => {
            return (
              <Tr
                key={project.projectId}
                id={project.projectRefId}
                onClick={handleRowClick}
                cursor='pointer'
                borderRadius='3xl'
                _hover={{ bg: 'cyan.300', color: 'white', borderRadius: '3xl' }}
              >
                <Td>
                  <Text>{project.projectRefId}</Text>
                </Td>
                <Td>
                  <Text>{project.name}</Text>
                </Td>
                <Td>
                  <Text>{project.state}</Text>
                </Td>
                <Td>
                  <Text>{project.owner}</Text>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
};
