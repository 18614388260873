import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Select,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { ConfigType } from '../../types/types';
import { TemplateConfigType, TemplateDeviceType } from '../../utils/mock';

export type CreateConfigFormType = Omit<ConfigType, 'organizationId' | 'projectId' | 'configId'>;

type CreateConfigFormProps = {
  templateDevices: TemplateDeviceType[];
  templateConfigs: TemplateConfigType[];
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (form: CreateConfigFormType) => void;
};

export const CreateConfigForm: React.FC<CreateConfigFormProps> = ({ templateDevices, templateConfigs, isOpen, onClose, onSubmit }) => {
  const [form, setForm] = useState<CreateConfigFormType>({
    deviceBrand: '',
    deviceModel: '',
    note: '',
    configValues: []
  });
  const [templateDevice, setTemplateDevice] = useState<TemplateDeviceType | null>(templateDevices[0] ?? null);
  const [templateConfig, setTemplateConfig] = useState<TemplateConfigType | null>(null);

  useEffect(() => {
    if (templateConfig) {
      setForm({
        deviceBrand: templateConfig.deviceBrand,
        deviceModel: templateConfig.deviceModel,
        note: templateConfig.note,
        configValues: templateConfig.configValues
      });
    }
  }, [templateConfig]);

  useEffect(() => {
    const templateConfig = templateConfigs.find(tc => tc.deviceBrand === templateDevice?.brand && tc.deviceModel === templateDevice?.model);
    if (templateConfig) setTemplateConfig(templateConfig);
  }, [templateDevice]);

  const handleInputChange = (event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    if (name === 'templateDeviceId') {
      const templateDevice = templateDevices.find(templateDevice => templateDevice.templateDeviceId === value);
      if (templateDevice) setTemplateDevice(templateDevice);
      return;
    } else if (name === 'templateConfigId') {
      const templateConfig = templateConfigs.find(templateConfig => templateConfig.templateConfigId === value);
      if (templateConfig) setTemplateConfig(templateConfig);
      return;
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit(form);
  };

  return (
    <Drawer isOpen={isOpen} placement='right' onClose={onClose} size='md'>
      <form onSubmit={handleSubmit}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader marginBottom='10' alignSelf='center'>
            <Text fontSize='3xl' as='b'>
              Create device config
            </Text>
          </DrawerHeader>

          <DrawerBody>
            <Text color='blue.900' as='b'>
              Device
            </Text>
            <Select
              marginTop='2'
              marginBottom='4'
              name='templateDeviceId'
              value={templateDevice?.templateDeviceId}
              onChange={handleInputChange}
            >
              {templateDevices.map(device => (
                <option key={device.templateDeviceId} value={device.templateDeviceId}>
                  {device.brand} {device.model}
                </option>
              ))}
            </Select>
            <VStack align='start'>
              <Text color='blue.900' as='b' whiteSpace='pre-line'>
                <b>Device Brand: </b>
                {templateDevice?.brand}
              </Text>

              <Text color='blue.900' as='b' whiteSpace='pre-line'>
                Device Model: {templateDevice?.model}
              </Text>
            </VStack>
            <Text color='blue.900' as='b'>
              Config
            </Text>
            <Select
              marginTop='2'
              marginBottom='4'
              name='templateConfigId'
              value={templateConfig?.templateConfigId}
              onChange={handleInputChange}
            >
              {templateConfigs.map(templateConfig => (
                <option key={templateConfig.templateConfigId} value={templateConfig.templateConfigId}>
                  {templateConfig.note}
                </option>
              ))}
            </Select>
            {templateConfig && (
              <TableContainer mt={2}>
                <Table variant='simple' size='sm' fontSize='sm'>
                  <Thead>
                    <Tr>
                      <Th>Index</Th>
                      <Th>Title</Th>
                      <Th>Samples</Th>
                      <Th>Minimum</Th>
                      <Th>Maximum</Th>
                      <Th>Dimension</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {templateConfig?.configValues.length > 0 &&
                      templateConfig?.configValues.map((value, index) => (
                        <Tr key={index}>
                          <Td>{value.idx}</Td>
                          <Td>{value.title}</Td>
                          <Td>{value.samples}</Td>
                          <Td>{value.physicalMinimum}</Td>
                          <Td>{value.physicalMaximum}</Td>
                          <Td>{value.physicalDimension}</Td>
                        </Tr>
                      ))}
                  </Tbody>
                </Table>
              </TableContainer>
            )}
          </DrawerBody>

          <DrawerFooter>
            <Button variant='outline' mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme='blue' type='submit' onClick={onClose}>
              Create
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </form>
    </Drawer>
  );
};
