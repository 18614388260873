import { Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue } from '@chakra-ui/react';
import { SubjectType } from '../../types/types';

type SubjectTableProps = {
  subjects: SubjectType[];
  onRowClick?: (subject: SubjectType | null) => void;
};

export const SubjectTable: React.FC<SubjectTableProps> = ({ subjects, onRowClick }) => {
  const handleRowClick = (e: React.MouseEvent<HTMLTableRowElement>) => {
    e.stopPropagation();
    if (onRowClick) {
      const subjectId = e.currentTarget.id;
      onRowClick(subjects.find(subject => subject.subjectId == subjectId) ?? null);
    }
  };

  return (
    <TableContainer>
      <Table variant='simple'>
        <Thead>
          <Tr bg={useColorModeValue('gray.300', 'gray.900')}>
            <Th>
              <Text>ID</Text>
            </Th>
            <Th>
              <Text>Subject label</Text>
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {subjects.map(subject => (
            <Tr
              key={subject.subjectId}
              id={subject.subjectId}
              onClick={handleRowClick}
              cursor='pointer'
              borderRadius='3xl'
              _hover={{ bg: 'cyan.300', color: 'white', borderRadius: '3xl' }}
            >
              <Td>
                <Text>{subject.subjectRefId}</Text>
              </Td>
              <Td>
                <Text>{subject.label}</Text>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};
