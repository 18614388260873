import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Input,
  Select,
  Text
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { DeviceType } from '../../types/types';
import { TemplateDeviceType } from '../../utils/mock';

export type CreateDeviceFormType = Omit<DeviceType, 'organizationId' | 'deviceId'>;

type CreateDeviceFormProps = {
  templateDevices: TemplateDeviceType[];
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (form: CreateDeviceFormType) => void;
};

export const CreateDeviceForm: React.FC<CreateDeviceFormProps> = ({ templateDevices, isOpen, onClose, onSubmit }) => {
  const [snError, setSnError] = useState<string | null>(null);

  const [form, setForm] = useState<CreateDeviceFormType>({
    brand: '',
    model: '',
    sn: ''
  });

  const [templateDevice, setTemplateDevice] = useState<TemplateDeviceType | null>(templateDevices[0] ?? null);
  const [sn, setSn] = useState<string>('');

  useEffect(() => {
    if (templateDevice) {
      setForm({
        brand: templateDevice.brand,
        model: templateDevice.model,
        sn: sn
      });
    }
  }, [templateDevice, sn]);

  const handleInputChange = (event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    if (name === 'sn') {
      setSn(value);
      return;
    } else if (name === 'templateDeviceId') {
      const templateDevice = templateDevices.find(td => td.templateDeviceId === value);
      if (templateDevice) setTemplateDevice(templateDevice);
      return;
    }
  };

  const validateForm = () => {
    let ret = true;

    if (!form.sn.trim()) {
      setSnError('SN cannot be empty.');
      ret = ret && false;
    } else {
      setSnError(null);
    }

    return ret;
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (validateForm()) {
      onSubmit(form);
      onClose();
    }
  };

  return (
    <Drawer isOpen={isOpen} placement='right' onClose={onClose} size='md'>
      <form onSubmit={handleSubmit}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader alignSelf='center'>
            <Text fontSize='3xl' as='b'>
              Register device
            </Text>
          </DrawerHeader>

          <DrawerBody>
            <Text color='blue.900' as='b'>
              Device
            </Text>
            <Select
              marginTop='2'
              marginBottom='4'
              name='templateDeviceId'
              value={templateDevice?.templateDeviceId}
              onChange={handleInputChange}
            >
              {templateDevices.map(templateDevice => (
                <option key={templateDevice.templateDeviceId} value={templateDevice.templateDeviceId}>
                  {templateDevice.brand} {templateDevice.model}
                </option>
              ))}
            </Select>
            <Text color='blue.900' as='b'>
              SN
            </Text>
            <Input marginTop='2' marginBottom='4' name='sn' value={form.sn} onChange={handleInputChange} isInvalid={snError !== null} />
          </DrawerBody>

          <DrawerFooter>
            <Button variant='outline' mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme='blue' type='submit'>
              Save
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </form>
    </Drawer>
  );
};
