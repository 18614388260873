import { useRef, useState } from 'react';
import { useEffectOnce } from 'usehooks-ts';

type DebugDataRefType = {
  lastIdx: number;
  lastTimestamp: Date;
};

type RecordDataRefType = {
  currentIdx?: number;
};

const useDebugSamplingRate = (recordDataRef: React.MutableRefObject<RecordDataRefType>) => {
  const [samplingRate, setSamplingRate] = useState(0);
  const debugDataRef = useRef<DebugDataRefType>({
    lastIdx: 0,
    lastTimestamp: new Date()
  });

  /* setup */
  useEffectOnce(() => {
    const debugIntervalId = setInterval(debugSamplingRate, 1000);
    return () => {
      clearInterval(debugIntervalId);
    };
  });

  const debugSamplingRate = () => {
    const currentIdx = recordDataRef.current.currentIdx;
    const lastIdx = debugDataRef.current.lastIdx;
    const currentTimestamp = new Date();
    const lastTimestamp = debugDataRef.current.lastTimestamp;

    debugDataRef.current.lastIdx = currentIdx ?? 0;
    debugDataRef.current.lastTimestamp = currentTimestamp;
    if (currentIdx) {
      const samplingRate = Math.round(((currentIdx - lastIdx) * 1000) / (currentTimestamp.getTime() - lastTimestamp.getTime()));
      setSamplingRate(samplingRate);
    }
  };

  return { samplingRate };
};

export default useDebugSamplingRate;
