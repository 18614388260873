import { ConfigType, DeviceType } from '../types/types';

export type TemplateDeviceType = Omit<DeviceType, 'organizationId' | 'deviceId' | 'sn'> & { templateDeviceId: string };
export type TemplateConfigType = Omit<ConfigType, 'organizationId' | 'projectId' | 'configId'> & { templateConfigId: string };

export const templateDevices: TemplateDeviceType[] = [
  {
    templateDeviceId: '01GWSM76CZQ57W5GNTW4Y4HBH0',
    brand: 'G.tec',
    model: 'Unicorn Hybrid Black'
  }
];

export const templateConfigs: TemplateConfigType[] = [
  {
    templateConfigId: '01GWSM3F13RMG5FFXFFV5M67B1',
    deviceBrand: 'G.tec',
    deviceModel: 'Unicorn Hybrid Black',
    note: 'EEG, Accelerometer, Gyroscope, Battery, Counter (250SPS)',
    configValues: [
      {
        idx: 0,
        title: 'bat',
        dataType: 'uint8be',
        samples: 250,
        digitalMinimum: 0,
        digitalMaximum: 15,
        physicalMinimum: 0,
        physicalMaximum: 100,
        physicalDimension: '%'
      },
      {
        idx: 1,
        title: 'eeg0',
        dataType: 'int24be',
        samples: 250,
        digitalMinimum: -8388608,
        digitalMaximum: 8388607,
        physicalMinimum: -750000,
        physicalMaximum: 750000,
        physicalDimension: 'uV'
      },
      {
        idx: 2,
        title: 'eeg1',
        dataType: 'int24be',
        samples: 250,
        digitalMinimum: -8388608,
        digitalMaximum: 8388607,
        physicalMinimum: -750000,
        physicalMaximum: 750000,
        physicalDimension: 'uV'
      },
      {
        idx: 3,
        title: 'eeg2',
        dataType: 'int24be',
        samples: 250,
        digitalMinimum: -8388608,
        digitalMaximum: 8388607,
        physicalMinimum: -750000,
        physicalMaximum: 750000,
        physicalDimension: 'uV'
      },
      {
        idx: 4,
        title: 'eeg3',
        dataType: 'int24be',
        samples: 250,
        digitalMinimum: -8388608,
        digitalMaximum: 8388607,
        physicalMinimum: -750000,
        physicalMaximum: 750000,
        physicalDimension: 'uV'
      },
      {
        idx: 5,
        title: 'eeg4',
        dataType: 'int24be',
        samples: 250,
        digitalMinimum: -8388608,
        digitalMaximum: 8388607,
        physicalMinimum: -750000,
        physicalMaximum: 750000,
        physicalDimension: 'uV'
      },
      {
        idx: 6,
        title: 'eeg5',
        dataType: 'int24be',
        samples: 250,
        digitalMinimum: -8388608,
        digitalMaximum: 8388607,
        physicalMinimum: -750000,
        physicalMaximum: 750000,
        physicalDimension: 'uV'
      },
      {
        idx: 7,
        title: 'eeg6',
        dataType: 'int24be',
        samples: 250,
        digitalMinimum: -8388608,
        digitalMaximum: 8388607,
        physicalMinimum: -750000,
        physicalMaximum: 750000,
        physicalDimension: 'uV'
      },
      {
        idx: 8,
        title: 'eeg7',
        dataType: 'int24be',
        samples: 250,
        digitalMinimum: -8388608,
        digitalMaximum: 8388607,
        physicalMinimum: -750000,
        physicalMaximum: 750000,
        physicalDimension: 'uV'
      },
      {
        idx: 9,
        title: 'acc0',
        dataType: 'int16be',
        samples: 250,
        digitalMinimum: -32768,
        digitalMaximum: 32767,
        physicalMinimum: -8,
        physicalMaximum: 8,
        physicalDimension: 'g'
      },
      {
        idx: 10,
        title: 'acc1',
        dataType: 'int16be',
        samples: 250,
        digitalMinimum: -32768,
        digitalMaximum: 32767,
        physicalMinimum: -8,
        physicalMaximum: 8,
        physicalDimension: 'g'
      },
      {
        idx: 11,
        title: 'acc2',
        dataType: 'int16be',
        samples: 250,
        digitalMinimum: -32768,
        digitalMaximum: 32767,
        physicalMinimum: -8,
        physicalMaximum: 8,
        physicalDimension: 'g'
      },
      {
        idx: 12,
        title: 'gyr0',
        dataType: 'int16be',
        samples: 250,
        digitalMinimum: -32768,
        digitalMaximum: 32767,
        physicalMinimum: -1000,
        physicalMaximum: 1000,
        physicalDimension: 'deg/s'
      },
      {
        idx: 13,
        title: 'gyr1',
        dataType: 'int16be',
        samples: 250,
        digitalMinimum: -32768,
        digitalMaximum: 32767,
        physicalMinimum: -1000,
        physicalMaximum: 1000,
        physicalDimension: 'deg/s'
      },
      {
        idx: 14,
        title: 'gyr2',
        dataType: 'int16be',
        samples: 250,
        digitalMinimum: -32768,
        digitalMaximum: 32767,
        physicalMinimum: -1000,
        physicalMaximum: 1000,
        physicalDimension: 'deg/s'
      },
      {
        idx: 15,
        title: 'counter',
        dataType: 'int32be',
        samples: 250,
        digitalMinimum: -8388608,
        digitalMaximum: 8388607,
        physicalMinimum: -8388608,
        physicalMaximum: 8388607,
        physicalDimension: ''
      }
    ]
  }
];
