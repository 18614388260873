import { useAuthenticator } from '@aws-amplify/ui-react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Logout = () => {
  const navigate = useNavigate();
  const { signOut } = useAuthenticator();

  useEffect(() => {
    signOut();
    navigate('/home');
  }, []);

  return <div>Loging out</div>;
};

export default Logout;
