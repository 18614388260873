import { useRef, useState } from 'react';
import { extractBuf } from '../libs';

type SerialRefType = {
  serialPort?: SerialPort;
  serialReader?: ReadableStreamDefaultReader<Uint8Array>;
  serialWriter?: WritableStreamDefaultWriter<Uint8Array>;
};

type SerialConnectionStatus = 'disconnected' | 'connecting' | 'connected' | 'error';

type UseSerialProps = {
  readCallback: (data: Uint8Array[]) => void;
};

const useSerial = ({ readCallback }: UseSerialProps) => {
  const serialRef = useRef<SerialRefType>({});

  const [status, setStatus] = useState<SerialConnectionStatus>('disconnected');

  const connect = async () => {
    if (status !== 'disconnected') return;
    try {
      setStatus('connecting');
      const serialPort = await navigator.serial.requestPort({
        // filters: [
        //   { usbProductId: 0x7523, usbVendorId: 0x1a86 },
        //   { usbProductId: 0xea60, usbVendorId: 0x10c4 }
        // ]
      });
      serialRef.current.serialPort = serialPort;

      await serialPort.open({ baudRate: 500000, bufferSize: 512 });
      if (!serialPort.readable) return false;

      const reader = serialPort.readable.getReader();
      setStatus('connected');

      processData(reader)
        .then(() => {
          console.log('done data processing');
          setStatus('disconnected');
        })
        .catch(err => {
          console.error('Error process data:', err);
          setStatus('error');
        });
    } catch (err: any) {
      if (err.name === 'NotFoundError') {
        console.log('No port selected by the user:', err);
        setStatus('disconnected');
      } else {
        console.error('Error connecting to serial port:', err);
        setStatus('error');
      }
    }
  };

  const processData = async (reader: ReadableStreamDefaultReader<Uint8Array>) => {
    try {
      let buf: Uint8Array = new Uint8Array();

      // eslint-disable-next-line no-constant-condition
      while (true) {
        const { value, done } = await reader.read();
        if (done) {
          console.log('reader done');
          break;
        }
        if (value === undefined) continue;

        /* combine buffer with new read */
        const tmpBuf = new Uint8Array(buf.length + value.length);
        tmpBuf.set(buf);
        tmpBuf.set(value, buf.length);

        const { buf: newBuf, outputArray } = extractBuf(tmpBuf);
        buf = newBuf;
        readCallback(outputArray);
      }
    } catch (error) {
      console.log(error);
    } finally {
      console.log('release');
      reader.releaseLock();
    }
  };

  const serialWrite = (buffer: Uint8Array) => {
    if (!serialRef.current.serialPort?.writable) {
      console.log('writable is not ready');
      return;
    }

    const writer = serialRef.current.serialPort.writable.getWriter();

    writer
      .write(buffer)
      .then(() => {
        console.log('Buffer sent successfully');
        writer.releaseLock();
      })
      .catch(err => {
        console.error(err);
      });
  };

  return { connect, status, serialWrite };
};

export default useSerial;
