import { useAuthenticator } from '@aws-amplify/ui-react';
import { Alert, AlertIcon, Button, useDisclosure, VStack } from '@chakra-ui/react';
import { API } from 'aws-amplify';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchProjects } from '../api/api';
import { BodyLayout } from '../components/BodyLayout';
import LoadingBox from '../components/LoadingBox';
import { Navbar } from '../components/Navbar';
import { CreateDeviceForm, CreateDeviceFormType } from '../components/templateDevice/CreateTemplateDeviceForm';
import { TemplateDeviceTable } from '../components/templateDevice/TemplateDeviceTable';
import { ProjectContext, ProjectContextType } from '../stores/ProjectContext';
import { DeviceType } from '../types/types';
import { templateDevices } from '../utils/mock';

const TemplateDevice: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useAuthenticator();

  const { projects, setProjects, devices, setDevices, configs, setConfigs } = useContext<ProjectContextType>(ProjectContext);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [createdDevice, setCreatedDevice] = useState<DeviceType | null>(null);

  useEffect(() => {
    if (!projects) {
      setIsLoading(true);
      fetchProjects()
        .then(res => {
          const { projects, devices } = res;
          setProjects(projects);
          setDevices(devices);
        })
        .catch(err => {
          setError(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [projects]);

  const createDevice = (form: CreateDeviceFormType) => {
    API.post('labcloud-api', 'devices', {
      body: {
        brand: form.brand,
        model: form.model,
        sn: form.sn
      }
    }).then(res => {
      const device = res['device'] as DeviceType;
      if (devices) {
        setDevices([...devices, device]);
        setCreatedDevice(device);
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 5000); // Hide the alert after 5 seconds
      }
    });
  };

  return (
    <Navbar userName={user.username ?? ''} organizationName='BCI Lab'>
      <BodyLayout>
        <VStack align='flex-end' mb={5}>
          <Button colorScheme='teal' onClick={onOpen}>
            + Device
          </Button>
        </VStack>
        {isLoading && <LoadingBox></LoadingBox>}
        {showAlert && createdDevice && (
          <Alert status='success' mb={5}>
            <AlertIcon />
            (ID: {createdDevice.deviceId}) {createdDevice.brand} {createdDevice.model} SN: {createdDevice.sn} is created.
          </Alert>
        )}
        {!isLoading && devices && (
          <>
            <CreateDeviceForm
              templateDevices={templateDevices}
              onSubmit={createDevice}
              onClose={onClose}
              isOpen={isOpen}
            ></CreateDeviceForm>
            <TemplateDeviceTable devices={devices}></TemplateDeviceTable>
          </>
        )}
      </BodyLayout>
    </Navbar>
  );
};

export default TemplateDevice;
