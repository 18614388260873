import { useAuthenticator } from '@aws-amplify/ui-react';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchProjects } from '../api/api';
import { BodyLayout } from '../components/BodyLayout';
import LoadingBox from '../components/LoadingBox';
import { Navbar } from '../components/Navbar';
import { TemplateConfigTable } from '../components/templateConfig/TemplateConfigTable';
import { ProjectContext, ProjectContextType } from '../stores/ProjectContext';

const TemplateConfig: React.FC = () => {
  const { deviceId } = useParams();
  const { user } = useAuthenticator();

  const { projects, setProjects, devices, setDevices, configs, setConfigs } = useContext<ProjectContextType>(ProjectContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    if (!projects) {
      setIsLoading(true);
      fetchProjects()
        .then(res => {
          const { projects, devices } = res;
          setProjects(projects);
          setDevices(devices);
        })
        .catch(err => {
          setError(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [projects]);

  return (
    <Navbar userName={user.username ?? ''} organizationName='BCI Lab'>
      <BodyLayout>
        {isLoading && <LoadingBox></LoadingBox>}
        {!isLoading && configs && (
          <>
            <TemplateConfigTable configs={configs}></TemplateConfigTable>
          </>
        )}
      </BodyLayout>
    </Navbar>
  );
};

export default TemplateConfig;
