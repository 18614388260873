import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack
} from '@chakra-ui/react';
import { ConfigType } from '../../types/types';

type ConfigDetailsModalProps = {
  config: ConfigType;
  isOpen: boolean;
  onClose: () => void;
};

const ConfigDetailsModal: React.FC<ConfigDetailsModalProps> = ({ config, isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size='2xl'>
      <ModalOverlay />
      <ModalContent bg='gray.100'>
        <ModalHeader>Configuration Details</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box mt={16} my={16} maxW='90%' display='flex' flexDirection='column' alignItems='center'>
            <VStack spacing={2} align='start'>
              <Text>
                <b>ID:</b> {config.configId}
              </Text>
              <Text>
                <b>Device Brand:</b> {config.deviceBrand}
              </Text>
              <Text>
                <b>Device Model:</b> {config.deviceModel}
              </Text>
              <Text>
                <b>Note:</b> {config.note}
              </Text>
              <Text>
                <b>Configuration Values:</b>
              </Text>
            </VStack>
            {config.configValues.length > 0 && (
              <TableContainer mt={2}>
                <Table variant='simple' size='sm' fontSize='sm'>
                  <Thead>
                    <Tr>
                      <Th>Index</Th>
                      <Th>Title</Th>
                      <Th>Samples</Th>
                      <Th>Minimum</Th>
                      <Th>Maximum</Th>
                      <Th>Dimension</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {config.configValues.map((value, index) => (
                      <Tr key={index}>
                        <Td>{value.idx}</Td>
                        <Td>{value.title}</Td>
                        <Td>{value.samples}</Td>
                        <Td>{value.physicalMinimum}</Td>
                        <Td>{value.physicalMaximum}</Td>
                        <Td>{value.physicalDimension}</Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            )}
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ConfigDetailsModal;
