import { Button, Table, TableContainer, Tbody, Td, Th, Thead, Tr, useColorModeValue } from '@chakra-ui/react';
import { DeviceType, FileType, SessionType, SubjectType } from '../../types/types';

type SessionTableProps = {
  sessions: SessionType[];
  subjects: SubjectType[];
  devices: DeviceType[];
  files: FileType[];
  onRowClick?: (session: SessionType | null) => void;
  onDownloadButtonClick?: (session: SessionType | null) => void;
};

export const SessionTable: React.FC<SessionTableProps> = ({ sessions, subjects, devices, files, onRowClick, onDownloadButtonClick }) => {
  const handleRowClick = (e: React.MouseEvent<HTMLTableRowElement>) => {
    e.stopPropagation();
    if (onRowClick) {
      const sessionId = e.currentTarget.id;
      onRowClick(sessions.find(session => session.sessionId == sessionId) ?? null);
    }
  };

  const handleDownloadButtonClick = (session: SessionType) => {
    if (onDownloadButtonClick) {
      onDownloadButtonClick(session);
    }
  };

  return (
    <TableContainer>
      <Table variant={'simple'}>
        <Thead>
          <Tr bg={useColorModeValue('gray.300', 'gray.900')}>
            <Th>ID</Th>
            <Th>Subject Label</Th>
            <Th>Device note</Th>
            <Th>Technician</Th>
            <Th>Session Time</Th>
            <Th>State</Th>
            <Th>Download</Th>
          </Tr>
        </Thead>
        <Tbody>
          {sessions.map(session => (
            <Tr
              key={session.sessionId}
              id={session.sessionId}
              onClick={handleRowClick}
              cursor='pointer'
              borderRadius='3xl'
              _hover={{ bg: 'cyan.300', color: 'white', borderRadius: '3xl' }}
            >
              <Td>{session.sessionRefId}</Td>
              <Td>{subjects.find(subjects => subjects.subjectId === session.subjectId)?.label}</Td>
              <Td>
                {'Model: ' +
                  devices.find(devices => devices.deviceId === session.deviceId)?.model +
                  ', SN:' +
                  devices.find(devices => devices.deviceId === session.deviceId)?.sn}
              </Td>
              <Td>{session.technician}</Td>
              <Td>
                {new Date(session.sessionAt).toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' })}{' '}
                {new Date(session.sessionAt).toLocaleTimeString('en-US', {
                  hour: '2-digit',
                  minute: '2-digit',
                  second: '2-digit',
                  hour12: false
                })}
              </Td>
              <Td>{session.state}</Td>
              <Td>
                {files.filter(file => file.sessionId === session.sessionId).length > 0 && (
                  <Button
                    colorScheme='teal'
                    size='sm'
                    onClick={e => {
                      e.stopPropagation();
                      handleDownloadButtonClick(session);
                    }}
                  >
                    Download
                  </Button>
                )}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};
