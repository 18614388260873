import { Avatar, Box, Flex, HStack, Link, Menu, MenuButton, MenuDivider, MenuItem, MenuList, Text, VStack } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { FiChevronDown } from 'react-icons/fi';
import { NavLink as RouterLink } from 'react-router-dom';

type LinkItemType = {
  name: string;
  to: string;
};

const linkItems: LinkItemType[] = [
  {
    name: 'Projects',
    to: '/projects'
  },
  {
    name: 'Devices',
    to: '/devices'
  }
];

type NavbarProps = {
  children: ReactNode;
  userName: string;
  organizationName: string;
};

type NavItemProps = {
  to: string;
  children: ReactNode;
};

const NavItem = ({ to, children }: NavItemProps) => {
  return (
    <Link
      px={2}
      py={1}
      rounded={'md'}
      _hover={{
        textDecoration: 'none',
        bg: 'gray.200'
      }}
      as={RouterLink}
      to={to}
    >
      {children}
    </Link>
  );
};

export const Navbar: React.FC<NavbarProps> = ({ children, userName, organizationName }) => {
  return (
    <>
      <Box bg={'gray.300'} px={4}>
        <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
          <HStack spacing={8} alignItems={'center'}>
            <Link href='/' _hover={{ textDecoration: 'none' }}>
              <Box bg={'white'} borderRadius={'md'} ml={4} mr={4} p={1} pl={2} pr={2}>
                <Text fontSize='2xl' fontFamily='monospace' fontWeight='bold'>
                  LabCloud
                </Text>
              </Box>
            </Link>
            <HStack as={'nav'} spacing={4} display={'flex'}>
              {linkItems.map(link => (
                <NavItem key={link.name} to={link.to}>
                  {link.name}
                </NavItem>
              ))}
            </HStack>
          </HStack>
          <Flex alignItems={'center'}>
            <Menu>
              <MenuButton py={2} transition='all 0.3s' cursor={'pointer'} _focus={{ boxShadow: 'none' }}>
                <HStack spacing='8px'>
                  <Avatar size={'sm'} name={userName} />
                  <VStack display={'flex'} alignItems='flex-start' spacing='1px' ml='2'>
                    <Text fontSize='sm'>{userName}</Text>
                    <Text fontSize='xs' color='gray.600'>
                      {organizationName}
                    </Text>
                  </VStack>
                  <FiChevronDown />
                </HStack>
              </MenuButton>
              <MenuList>
                <MenuItem as='a' href='/logout' fontSize='sm'>
                  Profile
                </MenuItem>
                <MenuDivider />
                <MenuItem as='a' href='/logout' fontSize='sm'>
                  Sign Out
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        </Flex>
      </Box>
      <Box>{children}</Box>
    </>
  );
};
