import { AxisBottom, AxisLeft } from '@visx/axis';
import { GridColumns } from '@visx/grid';
import { scaleLinear } from '@visx/scale';
import { Line, LinePath } from '@visx/shape';
import { Text } from '@visx/text';
import { useMemo } from 'react';
import { DeviceConfig } from '../../libs';
// https://airbnb.io/visx/axis
// https://airbnb.io/visx/brush
// https://dev.to/mariazentsova/building-a-line-chart-in-react-with-visx-1jkp

type PlotProps = {
  /* device config */
  deviceConfig: DeviceConfig;
  /* y in range of -1, 1 */
  signals: { x: number; y: number }[][];
  annotations: { x: number; title: string }[];
  amplitudes: number[];
  /* width in pixels */
  outerWidth?: number;
  /* height in pixels */
  outerHeight?: number;
};

export const Plotter: React.FC<PlotProps> = (props: PlotProps) => {
  const { deviceConfig, signals, annotations, amplitudes, outerWidth = 1000, outerHeight = 800 } = props;

  const backgroundColor = '#F5E9CF';
  const margin = {
    top: 40,
    right: 60,
    bottom: 30,
    left: 40
  };

  /* plot area */
  const width = useMemo(() => outerWidth - margin.left - margin.right, [margin, outerWidth]);
  const height = useMemo(() => outerHeight - margin.top - margin.bottom, [margin, outerHeight]);

  if (width < 10) return null;

  /* space between plot in pixel */
  const scalePadding = 10;
  /* one plot height */
  const scaleHeight = useMemo(() => height / deviceConfig.Channels.length - scalePadding, [deviceConfig, height]);

  const xScale = useMemo(
    () =>
      scaleLinear({
        /* -5 seconds - 0 second */
        domain: [-5, 0],
        range: [0, width]
      }),
    [width]
  );

  const channelScales = useMemo(
    () =>
      deviceConfig.Channels.map((channel, ch) => {
        const digitalDisplayMinimum = deviceConfig.Displays[ch].digitalDisplayMinimum;
        const digitalDisplayMaximum = deviceConfig.Displays[ch].digitalDisplayMaximum;

        return scaleLinear({
          /* -5 seconds - 0 second */
          domain: [digitalDisplayMinimum, digitalDisplayMaximum],
          range: [scaleHeight, 0]
        });
      }),
    [deviceConfig, scaleHeight]
  );

  const amplitudeText = useMemo(() => {
    return {
      text: amplitudes.map((amplitude, ch) => `${amplitude.toFixed(1)} ${deviceConfig.Channels[ch].physicalDimension}`)
    };
  }, [deviceConfig, amplitudes]);

  return (
    <svg width={outerWidth} height={outerHeight}>
      <rect x={0} y={0} width={outerWidth} height={outerHeight} fill={backgroundColor} rx={0} />
      <g transform={`translate(${margin.left},${margin.top})`}>
        <GridColumns scale={xScale} width={width} height={height} stroke='#e0e0e0' />
        {signals.map((signal, ch) => {
          const text = amplitudeText?.text[ch];
          let color = 'black';
          if (text?.indexOf('uV') > 0) {
            const level = parseInt(text.split('uV')[0]);
            if (level > 500) color = 'red';
            else color = 'green';
          }

          return (
            <g key={`scale-${ch}`} transform={`translate(0, ${ch * (scaleHeight + scalePadding)})`}>
              <AxisLeft
                scale={channelScales[ch]}
                numTicks={1}
                tickFormat={d => ''}
                label={`ch-${deviceConfig.Channels[ch].title}`}
                labelOffset={5}
                strokeWidth={2}
              />
              <LinePath
                data={signals[ch]}
                x={d => xScale(d.x)}
                y={d => channelScales[ch](d.y)}
                stroke='#333'
                strokeWidth={1}
                strokeOpacity={1}
                shapeRendering='geometricPrecision'
                markerMid='url(#marker-circle)'
              />
              <Text x={width + 5} y={scaleHeight / 2} verticalAnchor='middle' fontSize={10} fill={color}>
                {amplitudeText.text[ch]}
              </Text>
            </g>
          );
        })}
        {annotations.map((annotation, idx) => (
          <g key={`annotation-${idx}`}>
            <Line
              from={{ x: xScale(annotation.x), y: 0 }}
              to={{ x: xScale(annotation.x), y: height }}
              stroke={'#333'}
              strokeWidth={1}
              pointerEvents='none'
            />
            <Text x={xScale(annotation.x)} y={-5} textAnchor='middle' verticalAnchor='end' fontSize={12}>
              {annotation.title}
            </Text>
          </g>
        ))}
        <AxisBottom scale={xScale} top={height} numTicks={5} strokeWidth={2} />
      </g>
    </svg>
  );
};
