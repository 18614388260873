import { Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue } from '@chakra-ui/react';
import { DeviceType } from '../../types/types';

type DeviceTableProps = {
  devices: DeviceType[];
  onRowClick?: (device: DeviceType | null) => void;
};

export const TemplateDeviceTable: React.FC<DeviceTableProps> = ({ devices, onRowClick }) => {
  const handleRowClick = (e: React.MouseEvent<HTMLTableRowElement>) => {
    e.stopPropagation();
    if (onRowClick) {
      const deviceId = e.currentTarget.id;
      onRowClick(devices.find(device => device.deviceId == deviceId) ?? null);
    }
  };

  return (
    <TableContainer>
      <Table variant='simple'>
        <Thead>
          <Tr bg={useColorModeValue('gray.300', 'gray.900')}>
            <Th>
              <Text align='center'>ID</Text>
            </Th>
            <Th>
              <Text align='center'>Band</Text>
            </Th>
            <Th>
              <Text align='center'>Model</Text>
            </Th>
            <Th>
              <Text align='center'>SN</Text>
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {devices.map(device => (
            <Tr
              key={device.deviceId}
              id={device.deviceId}
              onClick={handleRowClick}
              cursor='pointer'
              borderRadius='3xl'
              _hover={{ bg: 'cyan.300', color: 'white', borderRadius: '3xl' }}
            >
              <Td>
                {' '}
                <Text align='center'>{device.deviceId}</Text>
              </Td>
              <Td>
                <Text align='center'>{device.brand}</Text>
              </Td>
              <Td>
                <Text align='center'>{device.model}</Text>
              </Td>
              <Td>
                <Text align='center'>{device.sn}</Text>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};
