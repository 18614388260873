import { API } from 'aws-amplify';
import { AnnotationType, ConfigType, DeviceType, FileType, ProjectType, SessionType, SubjectType } from '../types/types';

export const fetchProjects = () => {
  console.log('fetch projects');
  return Promise.all([API.get('labcloud-api', `projects`, {}), API.get('labcloud-api', `devices`, {})]).then(res => {
    const projects = res[0]['projects'] as ProjectType[];
    const devices = res[1]['devices'] as DeviceType[];
    return { projects, devices };
  });
};

export const fetchProjectBundle = (projectId: string) => {
  console.log(`fetch project ${projectId} bundle`);
  return Promise.all([
    API.get('labcloud-api', `projects/${projectId}/subjects`, {}),
    API.get('labcloud-api', `projects/${projectId}/sessions`, {}),
    API.get('labcloud-api', `projects/${projectId}/configs`, {}),
    API.get('labcloud-api', `projects/${projectId}/annotations`, {})
  ]).then(res => {
    const subjects = res[0]['subjects'] as SubjectType[];
    const sessions = res[1]['sessions'] as SessionType[];
    const configs = res[2]['configs'] as ConfigType[];
    const annotations = res[3]['annotations'] as AnnotationType[];

    return { subjects, sessions, configs, annotations };
  });
};

export const fetchFiles = (projectId: string, sessionId: string) => {
  console.log(`fetch files`);
  return API.get('labcloud-api', `projects/${projectId}/sessions/${sessionId}/files`, {}).then(res => {
    const files = res?.['files'] as FileType[];
    return files;
  });
};
