import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack
} from '@chakra-ui/react';
import { ConfigType, DeviceType, SessionType } from '../../types/types';
import { parseDateTime } from '../../utils/time';

type SessionDetailsModalProps = {
  session: SessionType;
  device: DeviceType;
  config: ConfigType;
  isOpen: boolean;
  onClose: () => void;
};

const SessionDetailsModal: React.FC<SessionDetailsModalProps> = ({ session, device, config, isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size='2xl'>
      <ModalOverlay />
      <ModalContent bg='gray.100'>
        <ModalHeader>Session Details</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box mt={16} my={16} maxW='90%' display='flex' flexDirection='column' alignItems='center'>
            <VStack align='start'>
              <Text>
                <b>ID:</b> {session.sessionRefId}
              </Text>
              <Text>
                <b>Session Time:</b> {parseDateTime(session.sessionAt)}
              </Text>
              <Text>
                <b>State:</b> {session.state}
              </Text>
              <Text>
                <b>Condition: </b>
                {session.condition === 'not-specified' ? 'Not Specified' : session.condition === 'eyes-open' ? 'Eyes Open' : 'Eyes Closed'}
              </Text>
              <Text>
                <b>Technician: </b> {session.technician}
              </Text>
              {session.note && (
                <Text>
                  <b>Note: </b> {session.note}
                </Text>
              )}
              <Text>
                <b>Device Brand: </b>
                {device.brand}
              </Text>
              <Text>
                <b>Device Model: </b>
                {device.model}
              </Text>
              <Text>
                <b>Device SN: </b>
                {device.sn}
              </Text>
              <Text>
                <b>Note:</b> {config.note}
              </Text>
              <Text>
                <b>Device Config:</b>
              </Text>
            </VStack>
            {config.configValues.length > 0 && (
              <TableContainer mt={2}>
                <Table variant='simple' size='sm' fontSize='sm'>
                  <Thead>
                    <Tr>
                      <Th>Index</Th>
                      <Th>Title</Th>
                      <Th>Samples</Th>
                      <Th>Minimum</Th>
                      <Th>Maximum</Th>
                      <Th>Dimension</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {config.configValues.map((value, index) => (
                      <Tr key={index}>
                        <Td>{value.idx}</Td>
                        <Td>{value.title}</Td>
                        <Td>{value.samples}</Td>
                        <Td>{value.physicalMinimum}</Td>
                        <Td>{value.physicalMaximum}</Td>
                        <Td>{value.physicalDimension}</Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            )}
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default SessionDetailsModal;
