import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Input,
  Select,
  Text,
  Textarea
} from '@chakra-ui/react';
import { useState } from 'react';
import { ConfigType, DeviceType, SessionType, SubjectType } from '../../types/types';

export type CreateSessionFormType = Omit<
  SessionType,
  'organizationId' | 'projectId' | 'sessionId' | 'sessionRefId' | 'sessionAt' | 'state'
>;

type CreateSessionFormProps = {
  subjects: SubjectType[];
  devices: DeviceType[];
  configs: ConfigType[];
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (form: CreateSessionFormType) => void;
};

export const CreateSessionForm: React.FC<CreateSessionFormProps> = ({ subjects, devices, configs, isOpen, onClose, onSubmit }) => {
  const [technicianError, setTechnicianError] = useState<string | null>(null);
  const [form, setForm] = useState<CreateSessionFormType>({
    subjectId: subjects[0]?.subjectId,
    deviceId: devices[0]?.deviceId,
    configId: configs[0]?.configId,
    condition: 'not-specified',
    technician: '',
    note: ''
  });

  const handleInputChange = (event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setForm(prevState => ({ ...prevState, [name]: value }));
  };

  const validateForm = () => {
    let ret = true;
    if (!form.technician.trim()) {
      setTechnicianError('Technician name cannot be empty.');
      ret = ret && false;
    } else {
      setTechnicianError(null);
    }
    return ret;
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (validateForm()) {
      onSubmit(form);
      onClose();
    }
  };

  return (
    <Drawer isOpen={isOpen} placement='right' onClose={onClose} size='md'>
      <form onSubmit={handleSubmit}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader marginBottom='10' alignSelf='center'>
            <Text fontSize='3xl' as='b'>
              Create session
            </Text>
          </DrawerHeader>

          <DrawerBody>
            <Text color='blue.900' as='b'>
              Subject
            </Text>
            <Select marginTop='2' marginBottom='4' name='subjectId' value={form.subjectId} onChange={handleInputChange}>
              {subjects.map(subject => (
                <option key={subject.subjectId} value={subject.subjectId}>
                  {subject.label}
                </option>
              ))}
            </Select>
            <Text color='blue.900' as='b'>
              Device
            </Text>
            <Select marginTop='2' marginBottom='4' name='deviceId' value={form.deviceId} onChange={handleInputChange}>
              {devices.map(device => (
                <option key={device.deviceId} value={device.deviceId}>
                  {device.model} SN:{device.sn}
                </option>
              ))}
            </Select>
            <Text color='blue.900' as='b'>
              Config
            </Text>
            <Select marginTop='2' marginBottom='4' name='configId' value={form.configId} onChange={handleInputChange}>
              {configs.map(config => (
                <option key={config.configId} value={config.configId}>
                  {config.note}
                </option>
              ))}
            </Select>
            <Text color='blue.900' as='b'>
              Record Condition
            </Text>
            <Select marginTop='2' marginBottom='4' name='condition' value={form.condition} onChange={handleInputChange}>
              <option value='not-specified'>Not specified</option>
              <option value='eyes-open'>Eyes open</option>
              <option value='eyes-closed'>Eyes closed</option>
            </Select>
            <Text color='blue.900' as='b'>
              Technician Name
            </Text>
            <Input
              marginTop='2'
              marginBottom='4'
              name='technician'
              value={form.technician}
              onChange={handleInputChange}
              isInvalid={technicianError !== null}
            />
            <Text color='blue.900' as='b'>
              Note
            </Text>
            <Textarea marginTop='2' marginBottom='4' name='note' value={form.note} onChange={handleInputChange} />
          </DrawerBody>

          <DrawerFooter>
            <Button variant='outline' mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme='blue' type='submit'>
              Create
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </form>
    </Drawer>
  );
};
