import { ReactNode, useMemo } from 'react';
import { BsPerson } from 'react-icons/bs';
import { FiActivity } from 'react-icons/fi';
import { HiAnnotation } from 'react-icons/hi';
import { TbDeviceHeartMonitor } from 'react-icons/tb';
import { LinkItemType, Sidebar } from '../Sidebar';

type ProjectSidebarProps = {
  children: ReactNode;
  projectRefId: string;
  projectName: string;
};

export const ProjectSidebar: React.FC<ProjectSidebarProps> = ({ children, projectRefId: projectId, projectName }) => {
  const linkItems: LinkItemType[] = useMemo(
    () => [
      { name: 'Session', icon: FiActivity, to: `/projects/${projectId}/sessions` },
      { name: 'Subject', icon: BsPerson, to: `/projects/${projectId}/subjects` },
      { name: 'Device', icon: TbDeviceHeartMonitor, to: `/projects/${projectId}/devices` },
      { name: 'Annotation', icon: HiAnnotation, to: `/projects/${projectId}/annotations` }
    ],
    [projectId]
  );

  return (
    <Sidebar title={projectName} linkItems={linkItems}>
      {children}
    </Sidebar>
  );
};
