export class Pipeline {
  private readonly filters: BaseFilter[];

  /**
   * Initializes the pipeline with a stack of filters.
   * @param filters Array of filters to apply to the input sample
   */
  constructor(filters?: BaseFilter[]) {
    if (!filters) filters = [];
    this.filters = filters;
  }

  /**
   * Processes an input sample through the pipeline.
   * @param x Input sample
   * @returns Output sample
   */
  process(x: number): number {
    let y = x;
    for (const filter of this.filters) {
      y = filter.process(y);
    }
    return y;
  }
}

export abstract class BaseFilter {
  /**
   * Processes an input sample using the filter.
   * @param x Input sample
   * @returns Output sample
   */
  abstract process(x: number): number;
}

/**
 * Implements an IIR filter with predefined coefficients
 * and delays for the numerator and denominator coefficients.
 */
export class IIRFilter extends BaseFilter {
  private readonly b: number[]; // Numerator coefficients
  private readonly a: number[]; // Denominator coefficients
  private readonly m: number; // Length of numerator delay line
  private readonly n: number; // Length of denominator delay line
  private readonly zx: number[]; // Delay line for numerator coefficients
  private readonly zy: number[]; // Delay line for denominator coefficients

  /**
   * Initializes the IIR filter with the given coefficients.
   * @param b Numerator coefficients
   * @param a Denominator coefficients
   */
  constructor(b: number[], a: number[]) {
    super();
    this.b = b;
    this.a = a.slice(1);
    this.m = this.b.length;
    this.n = this.a.length;
    this.zx = new Array(this.m).fill(0);
    this.zy = new Array(this.n).fill(0);
  }

  /**
   * Filters the input sample using the IIR filter.
   * @param x Input sample
   * @returns Output sample
   */
  filter(x: number): number {
    // Add the input sample to the delay line for the numerator coefficients
    this.zx.unshift(x);
    // Remove the oldest input sample from the delay line
    this.zx.pop();

    let y = 0;
    for (let i = 0; i < this.m; i++) {
      // Multiply each numerator coefficient by the corresponding delayed input sample
      y += this.b[i] * this.zx[i];
    }
    for (let i = 0; i < this.n; i++) {
      // Multiply each denominator coefficient by the corresponding delayed output sample
      y -= this.a[i] * this.zy[i];
    }

    // Add the output sample to the delay line for the denominator coefficients
    this.zy.pop();
    this.zy.unshift(y);

    return y;
  }

  /**
   * Processes an input sample using the IIR filter.
   * @param x Input sample
   * @returns Output sample
   */
  process(x: number): number {
    return this.filter(x);
  }
}

export class ClippingFilter extends BaseFilter {
  private readonly minThreshold: number;
  private readonly maxThreshold: number;

  /**
   * Initializes the clipping filter with the given coefficients and thresholds.
   * @param minThreshold Minimum absolute value of output sample
   * @param maxThreshold Maximum absolute value of output sample
   */
  constructor(minThreshold: number, maxThreshold: number) {
    super();
    this.minThreshold = minThreshold;
    this.maxThreshold = maxThreshold;
  }

  /**
   * Applies clipping to the output sample of the base filter.
   * @param x Output sample of the base filter
   * @returns Clipped output sample
   */
  clip(x: number): number {
    if (x > this.maxThreshold) {
      return this.maxThreshold;
    } else if (x < this.minThreshold) {
      return this.minThreshold;
    } else {
      return x;
    }
  }

  /**
   * Processes an input sample using the clipping filter.
   * @param x Input sample
   * @returns Output sample clipped to the thresholds
   */
  process(x: number): number {
    return this.clip(x);
  }
}
