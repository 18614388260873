import { Box, Spinner } from '@chakra-ui/react';

function LoadingBox() {
  return (
    <Box display='flex' alignItems='center' justifyContent='center' height='50vh'>
      <Spinner />
    </Box>
  );
}

export default LoadingBox;
