import { useAuthenticator } from '@aws-amplify/ui-react';
import { Alert, AlertIcon, Button, useDisclosure, VStack } from '@chakra-ui/react';
import { API } from 'aws-amplify';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchProjectBundle, fetchProjects } from '../api/api';
import { BodyLayout } from '../components/BodyLayout';
import LoadingBox from '../components/LoadingBox';
import { Navbar } from '../components/Navbar';
import { ProjectSidebar } from '../components/project/ProjectSidebar';
import { CreateSubjectForm, CreateSubjectFormType } from '../components/subject/CreateSubjectForm';
import SubjectDetailsModal from '../components/subject/SubjectDetailsModal';
import { SubjectTable } from '../components/subject/SubjectTable';
import { ProjectContext, ProjectContextType } from '../stores/ProjectContext';
import { SubjectType } from '../types/types';

const Subject: React.FC = () => {
  const { projectRefId } = useParams();
  const { user } = useAuthenticator();

  const {
    projects,
    setProjects,
    devices,
    setDevices,
    configs,
    setConfigs,
    currentProject,
    setCurrentProject,
    subjects,
    setSubjects,
    sessions,
    setSessions,
    annotations,
    setAnnotations,
    files,
    setFiles
  } = useContext<ProjectContextType>(ProjectContext);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isDetailsModalOpen, onClose: onDetailsModalClose, onOpen: onDetailsModalOpen } = useDisclosure();
  const [currentSubject, setCurrentSubject] = useState<SubjectType | null>(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [createdSubject, setCreatedSubject] = useState<SubjectType | null>(null);

  useEffect(() => {
    if (!projects) {
      /* load projects */
      setIsLoading(true);
      fetchProjects()
        .then(res => {
          const { projects, devices } = res;
          /* clear current project and bundle */
          setSubjects(null);
          setSessions(null);
          setConfigs(null);
          setAnnotations(null);
          setCurrentProject(null);
          setFiles(null);
          /* set projects */
          setProjects(projects);
          setDevices(devices);
        })
        .catch(err => {
          setError(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [projects]);

  useEffect(() => {
    if (projects) {
      const project = projects.find(project => project.projectRefId === projectRefId);
      if (!project) {
        console.error(`invalid project ${projectRefId}`);
        return;
      }
      /* check if project ref id is not changed */
      if (currentProject?.projectRefId === project.projectRefId) {
        return;
      }

      setIsLoading(true);
      fetchProjectBundle(project.projectId)
        .then(res => {
          const { subjects, sessions, annotations, configs } = res;
          setSubjects(subjects);
          setSessions(sessions);
          setConfigs(configs);
          setAnnotations(annotations);
          setFiles(null);
        })
        .catch(err => {
          setError(err);
        })
        .finally(() => {
          setIsLoading(false);
        });

      /* set project and reload bundle */
      setCurrentProject(project);
    }
  }, [projects]);

  const createSubject = (subject: CreateSubjectFormType) => {
    if (!currentProject?.projectId) {
      console.error('invalid project');
      return;
    }
    API.post('labcloud-api', `projects/${currentProject.projectId}/subjects`, {
      body: {
        label: subject.label,
        birthdate: subject.birthdate,
        gender: subject.gender,
        handedness: subject.handedness,
        note: subject.note
      }
    }).then(res => {
      const subject = res['subject'] as SubjectType;
      if (subjects) {
        setSubjects([...subjects, subject]);
        setCreatedSubject(subject);
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 5000); // Hide the alert after 5 seconds
      }
    });
  };

  const openDetailsModal = (subject: SubjectType | null) => {
    if (!subject) return;

    setCurrentSubject(subject);
    onDetailsModalOpen();
  };

  return (
    <Navbar userName={user.username ?? ''} organizationName='BCI Lab'>
      <ProjectSidebar projectName={currentProject?.name ?? ''} projectRefId={currentProject?.projectRefId ?? ''}>
        <BodyLayout>
          <VStack align='flex-end' mb={5}>
            <Button colorScheme='teal' onClick={onOpen}>
              + Subject {isDetailsModalOpen}
            </Button>
          </VStack>
          {isLoading && <LoadingBox></LoadingBox>}
          {showAlert && createdSubject && (
            <Alert status='success' mb={5}>
              <AlertIcon />
              (ID: {createdSubject.subjectRefId}) {createdSubject.label} is created.
            </Alert>
          )}
          {!isLoading && subjects && (
            <>
              <CreateSubjectForm onSubmit={createSubject} onClose={onClose} isOpen={isOpen}></CreateSubjectForm>
              {currentSubject && (
                <SubjectDetailsModal
                  subject={currentSubject}
                  isOpen={isDetailsModalOpen}
                  onClose={onDetailsModalClose}
                ></SubjectDetailsModal>
              )}
              <SubjectTable subjects={subjects} onRowClick={openDetailsModal}></SubjectTable>
            </>
          )}
        </BodyLayout>
      </ProjectSidebar>
    </Navbar>
  );
};

export default Subject;
