import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Input,
  Text
} from '@chakra-ui/react';
import { useState } from 'react';
import { ProjectType } from '../../types/types';

export type CreateProjectFormType = Omit<ProjectType, 'organizationId' | 'projectId' | 'projectRefId' | 'state'>;

type CreateProjectFormProps = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (form: CreateProjectFormType) => void;
};

export const CreateProjectForm: React.FC<CreateProjectFormProps> = ({ isOpen, onClose, onSubmit }) => {
  const [form, setForm] = useState<CreateProjectFormType>({
    name: '',
    owner: '',
    description: ''
  });

  const handleInputChange = (event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setForm(prevState => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit(form);
  };

  return (
    <Drawer isOpen={isOpen} placement='right' onClose={onClose} size='md'>
      <form onSubmit={handleSubmit}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader alignSelf='center'>
            <Text fontSize='3xl' as='b'>
              Create project
            </Text>
          </DrawerHeader>

          <DrawerBody>
            <Text color='blue.900' as='b'>
              Project name
            </Text>
            <Input name='name' onChange={handleInputChange} value={form.name} marginTop='2' marginBottom='4' />
            <Text color='blue.900' as='b'>
              Project owner
            </Text>
            <Input name='owner' onChange={handleInputChange} value={form.owner} marginTop='2' />
          </DrawerBody>

          <DrawerFooter>
            <Button variant='outline' mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme='blue' type='submit' onClick={onClose}>
              Save
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </form>
    </Drawer>
  );
};
