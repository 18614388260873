import { Channel, convertPhysicalToDigital, DeviceConfig, Display, transformSample } from '../device';
import { ClippingFilter, IIRFilter, Pipeline } from '../filter';
import { LowLevelDataType } from '../lowLevel';

const channels: Channel[] = [
  {
    idx: 0,
    title: 'bat',
    dataType: LowLevelDataType.Uint8Be,
    samples: 250,
    digitalMinimum: 0,
    digitalMaximum: 15,
    physicalMinimum: 0,
    physicalMaximum: 100,
    physicalDimension: '%'
  },
  ...new Array(8).fill(0).map((val, idx) => {
    return {
      idx: idx + 1,
      title: `eeg${idx}`,
      dataType: LowLevelDataType.Int24Le,
      samples: 250,
      digitalMinimum: -8388608,
      digitalMaximum: 8388607,
      physicalMinimum: -750000,
      physicalMaximum: 750000,
      physicalDimension: 'uV'
    };
  }),
  ...new Array(3).fill(0).map((val, idx) => {
    return {
      idx: idx + 9,
      title: `acc${idx}`,
      dataType: LowLevelDataType.Int16Be,
      samples: 250,
      digitalMinimum: -32768,
      digitalMaximum: 32767,
      physicalMinimum: -8,
      physicalMaximum: 8,
      physicalDimension: 'g'
    };
  }),
  ...new Array(3).fill(0).map((val, idx) => {
    return {
      idx: idx + 12,
      title: `gyr${idx}`,
      dataType: LowLevelDataType.Int16Be,
      samples: 250,
      digitalMinimum: -32768,
      digitalMaximum: 32767,
      physicalMinimum: -1000,
      physicalMaximum: 1000,
      physicalDimension: 'deg/s'
    };
  }),
  {
    idx: 15,
    title: 'counter',
    dataType: LowLevelDataType.Int32Be,
    samples: 250,
    digitalMinimum: -8388608,
    digitalMaximum: 8388607,
    physicalMinimum: -8388608,
    physicalMaximum: 8388607,
    physicalDimension: ''
  }
];

const displays: Display[] = [
  {
    samples: 50,
    preprocessingPipeline: new Pipeline([
      new ClippingFilter(convertPhysicalToDigital(channels[0], 0), convertPhysicalToDigital(channels[1], 100))
    ]),
    digitalDisplayMinimum: convertPhysicalToDigital(channels[0], 0),
    digitalDisplayMaximum: convertPhysicalToDigital(channels[0], 100),
    mode: 'max'
  },
  ...new Array(8).fill(0).map((val, idx) => {
    return {
      samples: 50,
      preprocessingPipeline: new Pipeline([
        new IIRFilter([0.9149691441130827, -1.8299382882261654, 0.9149691441130827], [1.0, -1.8226949251963085, 0.837181651256023]),
        new ClippingFilter(convertPhysicalToDigital(channels[idx + 1], -50 * 20), convertPhysicalToDigital(channels[idx + 1], 50 * 20))
      ]),
      digitalDisplayMinimum: convertPhysicalToDigital(channels[idx + 1], -50),
      digitalDisplayMaximum: convertPhysicalToDigital(channels[idx + 1], 50),
      mode: 'diff' as const
    };
  }),
  ...new Array(3).fill(0).map((val, idx) => {
    return {
      samples: 50,
      preprocessingPipeline: new Pipeline([
        new ClippingFilter(convertPhysicalToDigital(channels[idx + 9], -8), convertPhysicalToDigital(channels[idx + 9], 8))
      ]),
      digitalDisplayMinimum: convertPhysicalToDigital(channels[idx + 9], -2),
      digitalDisplayMaximum: convertPhysicalToDigital(channels[idx + 9], 2),
      mode: 'mean' as const
    };
  }),
  ...new Array(3).fill(0).map((val, idx) => {
    return {
      samples: 50,
      preprocessingPipeline: new Pipeline([
        new ClippingFilter(convertPhysicalToDigital(channels[idx + 12], -1000), convertPhysicalToDigital(channels[idx + 12], 1000))
      ]),
      digitalDisplayMinimum: convertPhysicalToDigital(channels[idx + 12], -250),
      digitalDisplayMaximum: convertPhysicalToDigital(channels[idx + 12], 250),
      mode: 'mean' as const
    };
  }),
  {
    samples: 50,
    preprocessingPipeline: new Pipeline([
      new ClippingFilter(convertPhysicalToDigital(channels[15], -8388608), convertPhysicalToDigital(channels[15], 8388607))
    ]),
    digitalDisplayMinimum: convertPhysicalToDigital(channels[15], -8388608),
    digitalDisplayMaximum: convertPhysicalToDigital(channels[15], 8388607),
    mode: 'max'
  }
];

export const deviceConfig: DeviceConfig = {
  Channels: channels,
  Displays: displays
};

/**
 * This function extracts the data from a given Uint8Array between specific signatures.
 *
 * @param buf The buffer array containing the data.
 *
 * @returns An object containing the new buffer array with the remaining bytes, and an array of extracted data packets.
 */
export const extractBuf = (buf: Uint8Array) => {
  const outputArray: Uint8Array[] = [];
  while (buf.length >= 45) {
    let startIdx = -1;
    /* search for start, end signature */
    for (let i = 0; i < buf.length - 45 + 1; i++) {
      if (buf[i] === 0xc0 && buf[i + 1] === 0x00 && buf[i + 43] === 0x0d && buf[i + 44] === 0x0a) {
        startIdx = i;
        break;
      }
    }
    if (startIdx >= 0) {
      /* if signature is found, push data to array, return new buf every bytes after end signature */
      const pack = new Uint8Array(41);
      pack.set(buf.subarray(startIdx + 2, startIdx + 45 - 2));
      outputArray.push(pack);

      const tmpBuf = new Uint8Array(buf.length - 45);
      tmpBuf.set(buf.subarray(startIdx + 45));
      buf = tmpBuf;
    } else {
      /* if signature is not found, return new buf for last 44 bytes */
      const tmpBuf = new Uint8Array(44);
      tmpBuf.set(buf.subarray(buf.length - 44));
      buf = tmpBuf;
      break;
    }
  }
  return { buf, outputArray };
};

/**
 * Converts a Uint8Array packet to an array of data points.
 * @param pack - The packet of raw data to be transformed into an array of data points.
 * @returns An array of data points, one for each channel in the device configuration.
 */
export const transform: (pack: Uint8Array) => number[] = (pack: Uint8Array) => {
  return transformSample(deviceConfig, pack);
};
