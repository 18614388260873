import { createContext, ReactNode, useState } from 'react';
import { AnnotationType, ConfigType, DeviceType, FileType, ProjectType, SessionType, SubjectType } from '../types/types';

export type ProjectContextType = {
  projects: ProjectType[] | null;
  setProjects: React.Dispatch<React.SetStateAction<ProjectType[] | null>>;
  devices: DeviceType[] | null;
  setDevices: React.Dispatch<React.SetStateAction<DeviceType[] | null>>;
  currentProject: ProjectType | null;
  setCurrentProject: React.Dispatch<React.SetStateAction<ProjectType | null>>;
  sessions: SessionType[] | null;
  setSessions: React.Dispatch<React.SetStateAction<SessionType[] | null>>;
  subjects: SubjectType[] | null;
  setSubjects: React.Dispatch<React.SetStateAction<SubjectType[] | null>>;
  configs: ConfigType[] | null;
  setConfigs: React.Dispatch<React.SetStateAction<ConfigType[] | null>>;
  annotations: AnnotationType[] | null;
  setAnnotations: React.Dispatch<React.SetStateAction<AnnotationType[] | null>>;
  files: FileType[] | null;
  setFiles: React.Dispatch<React.SetStateAction<FileType[] | null>>;
};

export const ProjectContext = createContext<ProjectContextType>({
  projects: null,
  setProjects: () => {},
  devices: null,
  setDevices: () => {},
  currentProject: null,
  setCurrentProject: () => {},
  sessions: null,
  setSessions: () => {},
  subjects: null,
  setSubjects: () => {},
  configs: null,
  setConfigs: () => {},
  annotations: null,
  setAnnotations: () => {},
  files: [],
  setFiles: () => {}
});

type ProjectProviderProps = {
  children: ReactNode;
};

export const ProjectProvider = ({ children }: ProjectProviderProps) => {
  const [projects, setProjects] = useState<ProjectType[] | null>(null);
  const [devices, setDevices] = useState<DeviceType[] | null>(null);

  const [currentProject, setCurrentProject] = useState<ProjectType | null>(null);
  const [sessions, setSessions] = useState<SessionType[] | null>(null);
  const [subjects, setSubjects] = useState<SubjectType[] | null>(null);
  const [configs, setConfigs] = useState<ConfigType[] | null>(null);
  const [annotations, setAnnotations] = useState<AnnotationType[] | null>(null);

  const [files, setFiles] = useState<FileType[] | null>(null);

  return (
    <ProjectContext.Provider
      value={{
        projects,
        setProjects,
        devices,
        setDevices,
        configs,
        setConfigs,
        currentProject,
        setCurrentProject,
        sessions,
        setSessions,
        subjects,
        setSubjects,
        annotations,
        setAnnotations,
        files,
        setFiles
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
};
